import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MonitoringDataService } from '../../../store/general/monitoring-data.service';
import { HistoricalTrackingDataService } from './historical-tracking-data.service';
import * as HTActions from './historical-tracking.actions';

@Injectable()
export class HistoricalTrackingEffects {
  getVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HTActions.getVehicleTrips),
      switchMap(action =>
        this.dataService.getVehicleTrips(action.time_0, action.time_1, action.vehicle).pipe(
          map(tripsStates => HTActions.setVehicleTrips({ tripsStates })),
          catchError(() => of(HTActions.setVehicleTrips({ tripsStates: null })))
        )
      )
    )
  );

  getVehicleHistoricalAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HTActions.getVehicleHistoricalAlerts),
      switchMap(action =>
        this.generalDataService.getVehicleAlerts(action.time_0, action.time_1, action.vehicle).pipe(
          map(alerts => HTActions.setVehicleHistoricalAlerts({ alerts })),
          catchError(() => of(HTActions.setVehicleHistoricalAlerts({ alerts: null })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: HistoricalTrackingDataService,
    private generalDataService: MonitoringDataService
  ) {}
}
