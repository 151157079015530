import { createAction, props } from '@ngrx/store';
import { UserProfile } from '../utility/auth.models';

export const RESET = '[Auth] Reset State';
export const reset = createAction(RESET);

export const getCSRFToken = createAction('[Auth] Get CSRF Token');
export const setCSRFToken = createAction(
  '[Auth] Set CSRF Token',
  props<{ csrfToken: string | null }>()
);

export const getSession = createAction('[Auth] Get Session');
export const setSession = createAction(
  '[Auth] Set Session',
  props<{ session: { isAuthenticated: boolean | undefined; user?: UserProfile | null } }>()
);

export const signOut = createAction('[Auth] Sign Out');

export const setUserProfile = createAction(
  '[Auth] Set User Profile',
  props<{ userProfile: UserProfile | null }>()
);
