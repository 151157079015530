import { ActionReducerMap } from '@ngrx/store';
import {
  geofenceManagementReducer,
  GeofenceManagementState,
} from '../pages/geofence-management/store/geofence-management.reducers';
import {
  historicalTrackingReducer,
  HistoricalTrackingState,
} from '../pages/historical-tracking/store/historical-tracking.reducer';
import {
  realTimeMonitoringReducer,
  RealTimeMonitoringState,
} from '../pages/real-time-monitoring/store/real-time-monitoring.reducers';
import { monitoringGeneralReducer, MonitoringGeneralState } from './general/monitoring.reducer';

export type MonitoringAppState = {
  monitoringGeneral: MonitoringGeneralState;
  realTimeMonitoring: RealTimeMonitoringState;
  historicalTracking: HistoricalTrackingState;
  geofenceManagement: GeofenceManagementState;
};

export const monitoringAppReducers: ActionReducerMap<MonitoringAppState> = {
  monitoringGeneral: monitoringGeneralReducer,
  realTimeMonitoring: realTimeMonitoringReducer,
  historicalTracking: historicalTrackingReducer,
  geofenceManagement: geofenceManagementReducer,
};
