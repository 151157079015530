import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { InsightsRoutingModule } from './insights-routing.module';
import { InsightsComponent } from './insights.component';
import { insightsGeneralReducer } from './store/general/insights.reducer';
import { InsightsGeneralEffects } from './store/general/insights.effects';

@NgModule({
  declarations: [InsightsComponent],
  imports: [
    CommonModule,
    InsightsRoutingModule,
    StoreModule.forFeature('insights', [insightsGeneralReducer]),
    EffectsModule.forFeature([InsightsGeneralEffects]),
  ],
})
export class InsightsModule {}
