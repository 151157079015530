import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { selectUserProfile } from '../store/auth.selectors';

@Pipe({
  name: 'hasPermissions',
})
export class HasPermissionsPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(permissions: string[]): Observable<boolean> {
    return this.store.select(selectUserProfile).pipe(
      map(user => {
        if (!permissions || permissions.length === 0) return true;

        return permissions.every(permission => user?.permissions?.includes(permission));
      })
    );
  }
}
