// Widget Data
export const mileageDataUrl = 'analytics/mileage_data/';
export const activityDataUrl = 'analytics/activity_data/';
export const alertsDataUrl = 'analytics/alerts_data/';
export const chargingDataUrl = 'analytics/charging_data/';
export const co2ReductionDataUrl = 'analytics/co2_reduction_data/';
// Widget Support Data
export const vehicleRankingDataUrl = 'analytics/vehicle_ranking/';
// Tile Data
export const usageChargingHeatmapUrl = 'analytics/usage_charging_heatmap/';
export const usageAnalyticsUrl = 'analytics/usage_analytics/';
export const chargingAnalyticsUrl = 'analytics/charging_analytics/';
export const geofenceAlertsAnalyticsUrl = 'analytics/geofence_alerts/';
// Date Range Data
export const dataDateRangeUrl = 'vehicle/vehicle_analytics_metadata/';
