import { createSelector } from '@ngrx/store';
import { selectFeatureAuth } from '../../store/app.selectors';
import { AuthState } from './auth.reducer';

export const selectLoadingStatus = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.loadingStatus
);

export const selectSession = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.isAuthenticated
);

export const selectUserProfile = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.userProfile
);

export const selectCSRFToken = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.csrfToken
);
