<div class="idle-warning-container">
  <button mat-icon-button (click)="onStay()">
    <mat-icon>
      close
    </mat-icon>
  </button>

  <div class="contents">
    <p class="mat-headline-3">
      <strong>
        Session Timeout
      </strong>
    </p>

    <p *ngIf="isIdle; then warn; else inquire"></p>
    <ng-template #warn>We will sign you out in {{ countdown }} seconds to protect your data</ng-template>
    <ng-template #inquire>You've been inactive for some time</ng-template>

    <div mat-dialog-actions class="inactivity-dialog-options">
      <button mat-flat-button color="primary" (click)="onStay()" cdkFocusInitial>Stay</button>
      <button mat-stroked-button color="primary" (click)="onLeave()">Sign Out</button>
    </div>
  </div>
</div>
