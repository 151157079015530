import { Action, createReducer, on } from '@ngrx/store';
import { VehiclesListDTO } from '../../real-time-monitoring/real-time-monitoring.models';
import { GeofenceDTO } from '../geofence-management.models';
import * as GeofenceManagementActions from './geofence-management.actions';

export type GeofenceManagementState = {
  loadingStatus: {
    geofenceList: boolean;
    vehicles: boolean;
  };
  geofenceList: GeofenceDTO[] | undefined;
  vehicles: VehiclesListDTO | undefined;
};

const initialState: GeofenceManagementState = {
  loadingStatus: {
    geofenceList: false,
    vehicles: false,
  },
  geofenceList: undefined,
  vehicles: undefined,
};

const reducer = createReducer<GeofenceManagementState>(
  initialState,
  // -------- Geofence List
  on(GeofenceManagementActions.getGeofenceList, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceList: true,
    },
  })),
  on(GeofenceManagementActions.setGeofenceList, (state, { geofenceList }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceList: false,
    },
    geofenceList,
  })),
  // -------- Geofence List
  on(GeofenceManagementActions.getVehicles, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicles: true,
    },
  })),
  on(GeofenceManagementActions.setVehicles, (state, { vehicles }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicles: false,
    },
    vehicles,
  }))
);

export function geofenceManagementReducer(
  state: GeofenceManagementState | undefined,
  action: Action
): any {
  return reducer(state, action);
}
export const geofenceManagementFeatureKey = 'geofenceManagement';
