import { createSelector } from '@ngrx/store';
import { selectFeatureGeneralMonitoring } from '../monitoring.selectors';
import { MonitoringGeneralState } from './monitoring.reducer';

export const selectLoadingStatus = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.loadingStatus
);

export const selectVehicleList = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.vehicleList
);

export const selectFleetList = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.fleetList
);

export const selectVehicleData = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.vehicleData
);

export const selectVehicleRawData = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.vehicleRawData
);

export const selectVehicleAlerts = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.vehicleAlerts
);

export const selectVehicleLog = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.vehicleLogs
);

export const selectVehicleChargingSchedules = createSelector(
  selectFeatureGeneralMonitoring,
  (state: MonitoringGeneralState) => state.chargingSchedules
);
