import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsageAnalyticsHeatmapComponent } from './usage-analytics-heatmap.component';
import { CardGridModule } from '../../../../core/card-grid/card-grid.module';
import { NgxTaMapsModule } from '@temerity-analytics/ngx-ta-maps';

@NgModule({
  declarations: [UsageAnalyticsHeatmapComponent],
  imports: [CommonModule, CardGridModule, NgxTaMapsModule],
  exports: [UsageAnalyticsHeatmapComponent],
})
export class UsageAnalyticsHeatmapModule {}
