import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from './auth/utility/auth.guard';
import { ZEVA_PAGE_VIEW_PERMISSION, ZevaSubsystems } from './core/core.static';
import { HomeComponent } from './core/home/home.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { hasPermissions } from './auth/permissions/permissions.guard';
import { MatSnackBar } from '@angular/material/snack-bar';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: ZevaSubsystems.Monitoring.slug },
      {
        path: ZevaSubsystems.Insights.slug,
        canActivate: [isAuthenticated, hasPermissions(ZEVA_PAGE_VIEW_PERMISSION['insights-main'])],
        loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule),
        data: { title: ZevaSubsystems.Insights.name },
      },
      {
        path: ZevaSubsystems.Analytics.slug,
        canActivate: [
          isAuthenticated,
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION['analytics-vehicle']),
        ],
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
        data: { title: ZevaSubsystems.Analytics.name },
      },
      {
        path: ZevaSubsystems.Monitoring.slug,
        canActivate: [isAuthenticated],
        loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
        data: { title: ZevaSubsystems.Monitoring.name },
      },
      {
        path: 'admin',
        canActivate: [isAuthenticated],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: { title: 'Admin' },
      },
    ],
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'error', component: GenericErrorComponent },
  { path: '**', redirectTo: 'error?code=404' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [MatSnackBar],
})
export class AppRoutingModule {}
