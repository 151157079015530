import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap, pairwise, tap } from 'rxjs/operators';

const ERROR_MESSAGES: { [key: number]: string } = {
  404: "This isn't the page you are looking for",
};

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit, OnDestroy {
  errorCode: number | string = 500;
  errorMessage = 'That came unexpected';

  errorCode$: Subscription | undefined;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    /** extract router event with currently opened page's data from the route setup */
    this.errorCode$ = this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any[]) => {
        console.log(event[0].urlAfterRedirects);
      });
  }

  ngOnDestroy(): void {
    this.errorCode$?.unsubscribe();
  }
}
