import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringComponent } from './monitoring.component';
import { RealTimeMonitoringStoreService } from './pages/real-time-monitoring/store/real-time-monitoring-store.service';
import { RealTimeMonitoringDataService } from './pages/real-time-monitoring/real-time-monitoring-data.service';
import { GeofenceManagementStoreService } from './pages/geofence-management/store/geofence-management-store.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { realTimeMonitoringReducer } from './pages/real-time-monitoring/store/real-time-monitoring.reducers';
import { historicalTrackingReducer } from './pages/historical-tracking/store/historical-tracking.reducer';
import { RealTimeMonitoringEffects } from './pages/real-time-monitoring/store/real-time-monitoring.effects';
import { HistoricalTrackingEffects } from './pages/historical-tracking/store/historical-tracking.effects';
import { monitoringGeneralReducer } from './store/general/monitoring.reducer';
import { MonitoringGeneralEffects } from './store/general/monitoring.effects';

@NgModule({
  declarations: [MonitoringComponent],
  imports: [
    CommonModule,
    MonitoringRoutingModule,
    StoreModule.forFeature('monitoring', [
      monitoringGeneralReducer,
      realTimeMonitoringReducer,
      historicalTrackingReducer,
    ]),
    EffectsModule.forFeature([
      MonitoringGeneralEffects,
      RealTimeMonitoringEffects,
      HistoricalTrackingEffects,
    ]),
  ],
  providers: [
    RealTimeMonitoringStoreService,
    RealTimeMonitoringDataService,
    GeofenceManagementStoreService,
  ],
})
export class MonitoringModule {}
