import { HttpErrorResponse } from '@angular/common/http';
import { RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable, OperatorFunction, catchError, takeWhile, tap } from 'rxjs';

/**
 * Apply mixins to a class that needs to
 * @param derivedConstructor
 * @param baseConstructors
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyMixins(derivedConstructor: any, baseConstructors: any[]): void {
  baseConstructors.forEach(baseConstructor => {
    Object.getOwnPropertyNames(baseConstructor.prototype).forEach(name => {
      Object.defineProperty(
        derivedConstructor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseConstructor.prototype, name) || Object.create(null)
      );
    });
  });
}

/**
 * Creates a promise that resolves after a number of seconds passed from the parameters.
 * @usage Halts async functions when used with async/await
 * @example
 * async function Func() {
 *  console.log("Uuugghhh, I'm gonna be late"); // this executes immediately
 *  await sleep(60 * 60 * 1000); // sleeps for 1 hour
 *  console.log("Damn, I'm late"); // this executes 1 hour later
 * }
 */
export function sleep(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Converts input to string and adds leading zeroes to fill length passed from the parameters
 * @example
 * addZeroes(64, 4) -> '0064'
 * addZeroes(2) -> '02'
 * @param input - input to be converted into string with leading zeroes
 * @param length - length of the resulting string
 */
export function addZeroes(input: number | string, length = 2): string {
  const s = typeof input === 'number' ? input.toString() : input;
  return ('0000000000' + s).slice(-1 * length);
}

/**
 * Recursively looks up the DOM tree to see
 * if the provided element has a parent with the provided selector
 * @param parentElement
 * @param tagName
 * @returns
 */
export function hasParentRecursive(
  element: HTMLElement | null,
  selector: string,
  lookBy: 'className' | 'id' | 'tagName'
): boolean {
  if (element === null || element.parentElement === null) return false;

  switch (lookBy) {
    case 'className':
      // console.log(element.classList, selector);
      if (element.classList.contains(selector)) return true;
      break;
    case 'id':
      // console.log(element.id, selector);
      if (element.id === selector) return true;
      break;
    case 'tagName':
      // console.log(element.tagName, selector);
      if (element.tagName === selector.toUpperCase()) return true;
      break;
  }

  return hasParentRecursive(element.parentElement, selector, lookBy);
}

/**
 * Converts input Date to UTC Date
 * @param time - input to be converted into UTC Date format
 */
export function convertToUTC(time: Date): Date {
  return new Date(
    time.getUTCFullYear(),
    time.getUTCMonth(),
    time.getUTCDate(),
    time.getUTCHours(),
    time.getUTCMinutes(),
    time.getUTCSeconds()
  );
}

/**
 * Returns a supposedly-unique title of a router outlet route for navigation & route animation
 * @param outlet {RouterOutlet} - reference to a local router outlet component
 */
export function getRouterOutletState(outlet: RouterOutlet): void {
  return outlet.activatedRouteData['title'];
}

/** Return a Date object of the current date at 00:00 time */
export const START_OF_TODAY = (() => {
  const today = new Date();
  today.setHours(0, 0);
  return today;
})();
/** Return a Date object of the current date at 23:59 time */
export const END_OF_TODAY = (() => {
  const today = new Date();
  today.setHours(23, 59);
  return today;
})();

// TODO: Enforce proper typing through templates
export function startRequestSequence(
  store: Store,
  actions: Actions,
  getAction: (props?: any) => unknown & TypedAction<any>,
  setAction: TypedAction<any>,
  paramSequence: number | unknown[],
  callback?: (action: any) => void
): Observable<any> {
  let sequenceStep = 0;

  const reactionFn = () => {
    if (typeof paramSequence === 'number') store.dispatch(getAction());
    else store.dispatch(getAction(paramSequence[sequenceStep]));
    ++sequenceStep;
  };
  reactionFn();

  return actions.pipe(
    ofType(setAction.type),
    // filter(state => state !== undefined && !Object.values(state).some(v => v === undefined)),
    tap(callback),
    takeWhile(
      () =>
        sequenceStep < (typeof paramSequence === 'number' ? paramSequence : paramSequence.length)
    ),
    tap(reactionFn)
  );
}

/**
 * HTTP Request interceptor for handled error responses that return a `200` status code
 * but carry an `error` member to signal that the backend knows of the error
 * but assumes it to be within expected behaviour.
 *
 * When caught throws an error so that it can be handled
 * within an observable stream with `catchError()` or in sync code with `try/catch` block
 *
 * @throws HttpErrorResponse({ error: 'Text message of the handler error' })
 */
export function handleErrorResponse<T>(): (stream$: Observable<T>) => Observable<T> {
  return stream$ =>
    stream$.pipe(
      tap((data: T) => {
        if (data && typeof data === 'object' && 'error' in data)
          throw new Error(data.error as string);
      })
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchErrorResponse: OperatorFunction<any, any> = catchError(
  (error: HttpErrorResponse) => {
    // ensure that error.error is a obj containing an error_message key
    // otherwise scrub the response to an unknown error message
    if (typeof error.error === 'object' && error.error !== null && error.error.error_message)
      throw new Error(error.error.error_message);

    if (typeof error.error === 'object' && error.error !== null && error.error.detail) {
      // e.g., from a permissions error in the backend
      throw new Error(error.error.detail);
    }

    if (Array.isArray(error.error)) {
      // e.g., from a ValidationError raised in the backend
      throw new Error(error.error.join('; '));
    }

    // scrub the response to an unknown error message
    throw new Error('An unexpected error occurred, please try again later.');
  }
);
