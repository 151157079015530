import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../../http-client-wrapper';
import { VehicleState } from '../../../../shared/vehicle/vehicle.models';
import { vehicleTripsUrl } from '../../../monitoring.urls';

@Injectable({ providedIn: 'root' })
export class HistoricalTrackingDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  getVehicleTrips(time_0: Date, time_1: Date, vehicle: number): Observable<VehicleState[]> {
    return this.httpClientWrapper.get(vehicleTripsUrl, { time_0, time_1, vehicle });
  }
}
