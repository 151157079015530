import { Action, createReducer, on } from '@ngrx/store';
import * as AdminActions from './admin.actions';
import { Provider, ProviderCredential } from '../../admin.models';

export type AdminGeneralState = {
  loadingStatus: {
    providers: boolean;
    providerCredentials: boolean;
  };
  providers?: Provider[] | null;
  providerCredentials?: ProviderCredential[] | null;
};

const initialState: AdminGeneralState = {
  loadingStatus: {
    providers: false,
    providerCredentials: false,
  },
};

const reducer = createReducer<AdminGeneralState>(
  initialState,
  // Reset State to initial
  on(AdminActions.clearState, () => ({
    ...initialState,
  })),
  // List of Supported Providers (Vehicle Brands)
  on(AdminActions.getSupportedProviders, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providers: true,
    },
  })),
  on(AdminActions.setSupportedProviders, (state, { providers }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providers: false,
    },
    providers,
  })),
  // Vehicle Provider Credentials
  on(AdminActions.getProviderCredentials, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providerCredentials: true,
    },
  })),
  on(AdminActions.setProviderCredentials, (state, { providerCredentials }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providerCredentials: false,
    },
    providerCredentials,
  }))
);

export function adminGeneralReducer(
  state: AdminGeneralState | undefined,
  action: Action
): AdminGeneralState {
  return reducer(state, action);
}
export const adminGeneralFeatureKey = 'adminGeneral';
