import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZEVA_PAGES } from '../core/core.static';
import { InsightsComponent } from './insights.component';

const insightsRoutes: Routes = [
  {
    path: '',
    component: InsightsComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
        data: { title: ZEVA_PAGES['insights-main'].name },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(insightsRoutes)],
  exports: [RouterModule],
})
export class InsightsRoutingModule {}
