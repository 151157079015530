import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxEchartsModule } from 'ngx-echarts';

import { AnalyticsModule } from './analytics/analytics.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthEffects } from './auth/store/auth.effects';
import { AuthInterceptor } from './auth/utility/auth.interceptor';
import { AuthService } from './auth/utility/auth.service';
import { CoreModule } from './core/core.module';
import { GlobalService } from './core/services/global.service';
import { InsightsModule } from './insights/insights.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { metaReducers, reducers } from './store/app.reducers';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  TA_ECHARTS_DEFAULT_OPTIONS,
  TA_ECHARTS_SERIES_DEFAULT_OPTIONS,
} from '@temerity-analytics/ngx-ta-echarts';
import { ZEVA_DEFAULT_CHART_OPTIONS } from './core/core.static';
import { GenericErrorComponent } from './generic-error/generic-error.component';

import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { IdleWarningDialogModule } from './core/idle-watch/idle-warning-dialog/idle-warning-dialog.module';
import { HasPermissionsPipe } from './has-permissions.pipe';

@NgModule({
  declarations: [
    AppComponent,
    // PageNotFoundComponent,
    GenericErrorComponent,
    HasPermissionsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([AuthEffects]),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    InsightsModule,
    AnalyticsModule,
    MonitoringModule,
    CoreModule,

    MatNativeDateModule,
    MatDialogModule,
    NgbModule,

    IdleWarningDialogModule,
  ],
  providers: [
    AuthService,
    GlobalService,
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-icons-round' } },
    {
      provide: TA_ECHARTS_DEFAULT_OPTIONS,
      useValue: ZEVA_DEFAULT_CHART_OPTIONS,
    },
    { provide: TA_ECHARTS_SERIES_DEFAULT_OPTIONS, useValue: { symbol: 'none', smooth: true } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
