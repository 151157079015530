import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleAlertsResponse } from '../../../monitoring/monitoring.models';
import { HttpClientWrapper } from '../../../http-client-wrapper';
import {
  ChargingSchedule,
  VehicleLogEntry,
  Fleet,
  FleetDTO,
  Vehicle,
  VehicleDTO,
  VehicleState,
} from '../../../shared/vehicle/vehicle.models';
import {
  chargingScheduleUrl,
  vehicleAlertsUrl,
  vehicleLogsUrl,
  vehicleDataUrl,
  vehicleFleetsUrl,
  vehicleListUrl,
  vehicleRawDataUrl,
} from '../../monitoring.urls';

@Injectable({ providedIn: 'root' })
export class MonitoringDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  /** Returns a list of all vehicles */
  getVehicleList(): Observable<Vehicle[]> {
    // return this.httpClientWrapper.get(`vehicle-list/`);
    return this.httpClientWrapper.get(vehicleListUrl);
  }
  /**
   * Creates new vehicle entries or modifies existing if passed VINs exist in the database.
   * @returns list of all vehicles: old ones + newly added
   */
  createVehicleList(vehicles: VehicleDTO[]): Observable<Vehicle[]> {
    return this.httpClientWrapper.post(vehicleListUrl, { vehicles });
  }
  /**
   * Permanently deletes vehicle entries with passed VINs
   * @returns list of all vehicles that remained in the database after deletion
   */
  deleteVehicleList(vehicleVINs: string[]): Observable<Vehicle[]> {
    return this.httpClientWrapper.delete(vehicleListUrl + vehicleVINs.join(','), {});
  }

  /** Returns a list of all Fleets with their assigned vehicles */
  getFleetList(): Observable<Fleet[]> {
    return this.httpClientWrapper.get(vehicleFleetsUrl);
  }
  /**
   * Creates new fleet entries or modifies existing if passed fleet_name exist in the database
   * @returns list of all fleets: old ones + newly added
   */
  createFleetList(fleets: FleetDTO[]): Observable<Fleet[]> {
    return this.httpClientWrapper.post(vehicleFleetsUrl, { fleets });
  }
  /**
   * Permanently deletes vehicle entries with passed fleet ids.
   * Unlinks all vehicles linked to those fleets
   * @returns list of all fleets that remained in the database after deletion
   */
  deleteFleetList(fleetIds: number[]): Observable<Fleet[]> {
    return this.httpClientWrapper.delete(vehicleFleetsUrl + fleetIds.join(','), {});
  }

  getVehicleData(vehicles: number[]): Observable<VehicleState[]> {
    return this.httpClientWrapper.post(vehicleDataUrl, { vehicles });
  }

  getVehicleAlerts(
    time_0?: string,
    time_1?: string,
    vehicle?: number[]
  ): Observable<VehicleAlertsResponse> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};
    if (time_0) params['time_0'] = time_0;
    if (time_1) params['time_1'] = time_1;
    if (vehicle) params['vehicle'] = vehicle;

    return this.httpClientWrapper.get(vehicleAlertsUrl, params);
  }

  getVehicleRawData(vehicles: number[]): Observable<any[]> {
    return this.httpClientWrapper.get(vehicleRawDataUrl, { vehicles });
  }

  getVehicleLogs(vehicles: number[]): Observable<VehicleLogEntry[]> {
    return this.httpClientWrapper.get(vehicleLogsUrl, { vehicles });
  }

  getChargingSchedules(vehicles: number[]): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.get(chargingScheduleUrl, { vehicles });
  }

  createChargingSchedule(
    schedule: ChargingSchedule,
    vehicles: number[]
  ): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.post(chargingScheduleUrl, { schedule, vehicles });
  }

  deleteChargingSchedule(schedule_id: number, vehicles: number[]): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.delete(`${chargingScheduleUrl}${schedule_id}`, { vehicles });
  }
}
