import { Action, createReducer, on } from '@ngrx/store';
import { VehicleAlertsResponse } from '../../../monitoring/monitoring.models';
import {
  ChargingSchedule,
  VehicleLogEntry,
  Fleet,
  Vehicle,
  VehicleState,
} from '../../../shared/vehicle/vehicle.models';
import { Loadable } from '../../../shared/loading-state/loadable';
import * as MonitoringActions from './monitoring.actions';

export type MonitoringGeneralState = {
  loadingStatus: {
    vehicleList: boolean;
    fleetList: boolean;
    vehicleData: boolean;
    vehicleRawData: boolean;
    vehicleAlerts: boolean;
    commandLogs: boolean;
  };
  vehicleList?: Vehicle[] | null;
  fleetList?: Fleet[] | null;
  vehicleData?: VehicleState[] | null;
  vehicleRawData?: any[] | null;
  vehicleAlerts?: VehicleAlertsResponse | null;
  vehicleLogs?: VehicleLogEntry[] | null;
  chargingSchedules: Loadable<ChargingSchedule[] | null>;
};

const initialState: MonitoringGeneralState = {
  loadingStatus: {
    vehicleList: false,
    fleetList: false,
    vehicleData: false,
    vehicleRawData: false,
    vehicleAlerts: false,
    commandLogs: false,
  },
  chargingSchedules: new Loadable(),
};

const reducer = createReducer<MonitoringGeneralState>(
  initialState,
  // -------- Vehicle List
  on(
    (MonitoringActions.getVehicleList,
    MonitoringActions.createFleetList,
    MonitoringActions.deleteFleetList),
    state => ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        vehicleList: true,
      },
    })
  ),
  on(MonitoringActions.setVehicleList, (state, { vehicleList, noUpdate }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleList: false,
    },
    vehicleList: noUpdate === undefined ? vehicleList : state.vehicleList,
  })),
  // -------- Fleet List
  on(
    (MonitoringActions.getFleetList,
    MonitoringActions.createFleetList,
    MonitoringActions.deleteFleetList),
    state => ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        fleetList: true,
      },
    })
  ),
  on(MonitoringActions.setFleetList, (state, { fleetList }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      fleetList: false,
    },
    fleetList,
  })),
  // -------- Vehicle Data
  on(MonitoringActions.getVehicleData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleData: true,
    },
  })),
  on(MonitoringActions.setVehicleData, (state, { vehicleData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleData: false,
    },
    vehicleData,
  })),
  // -------- Vehicle Raw Data
  on(MonitoringActions.getVehicleRawData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRawData: true,
    },
  })),
  on(MonitoringActions.setVehicleRawData, (state, { rawData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRawData: false,
    },
    vehicleRawData: rawData,
  })),
  // -------- Vehicle Alerts
  on(MonitoringActions.getVehicleAlerts, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: true,
    },
  })),
  on(MonitoringActions.setVehicleAlerts, (state, { alerts }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: false,
    },
    vehicleAlerts: alerts,
  })),
  // -------- Vehicle Command Log
  on(MonitoringActions.getVehicleLogs, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      commandLogs: true,
    },
  })),
  on(MonitoringActions.setVehicleLogs, (state, { vehicleLogs }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleLogs: false,
    },
    vehicleLogs,
  })),
  // -------- Charging Schedules
  on(MonitoringActions.getVehicleChargingSchedules, state => ({
    ...state,
    chargingSchedules: state.chargingSchedules.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleChargingSchedules, (state, { schedules, error }) => ({
    ...state,
    chargingSchedules: !error ? new Loadable(schedules) : state.chargingSchedules.errorCopy(error),
  }))
);

export function monitoringGeneralReducer(
  state: MonitoringGeneralState | undefined,
  action: Action
): MonitoringGeneralState {
  return reducer(state, action);
}
export const monitoringGeneralFeatureKey = 'monitoringGeneral';
