import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './components/grid/grid.component';
import { CardComponent } from './components/card/card.component';
import { CardHeaderDirective } from './directives/card/card-header.directive';
import { CardFooterDirective } from './directives/card/card-footer.directive';
import { GridItemDirective } from './directives/grid/grid-item.directive';
import { CardTitleDirective } from './directives/card/card-title.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    GridComponent,
    GridItemDirective,
    CardComponent,
    CardHeaderDirective,
    CardFooterDirective,
    CardTitleDirective,
  ],
  exports: [
    GridComponent,
    GridItemDirective,
    CardComponent,
    CardHeaderDirective,
    CardFooterDirective,
    CardTitleDirective,
  ],
})
export class CardGridModule {}
