import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AnalyticsAppState } from '../analytics.reducer';
import { AnalyticsDataService } from './analytics-data.service';
import * as AnalyticsActions from './analytics.actions';

@Injectable()
export class AnalyticsGeneralEffects {
  getVehicleTravelData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleTravelData),
      switchMap(action =>
        this.dataService
          .getVehicleTravelData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(travelData => AnalyticsActions.setVehicleTravelData({ travelData })),
            catchError(() => of(AnalyticsActions.setVehicleTravelDataError()))
          )
      )
    )
  );

  getVehicleActivityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleActivityData),
      switchMap(action =>
        this.dataService
          .getVehicleActivityData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(activityData => AnalyticsActions.setVehicleActivityData({ activityData })),
            catchError(() => of(AnalyticsActions.setVehicleActivityData({ activityData: null })))
          )
      )
    )
  );

  getVehicleAlertData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleAlertData),
      switchMap(action =>
        this.dataService
          .getVehicleAlertData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(alertData => AnalyticsActions.setVehicleAlertData({ alertData })),
            catchError(() => of(AnalyticsActions.setVehicleAlertData({ alertData: null })))
          )
      )
    )
  );

  getVehicleChargingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleChargingData),
      switchMap(action =>
        this.dataService
          .getVehicleChargingData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(chargingData => AnalyticsActions.setVehicleChargingData({ chargingData })),
            catchError(() => of(AnalyticsActions.setVehicleChargingData({ chargingData: null })))
          )
      )
    )
  );

  getUsageChargingHeatmapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getUsageChargingHeatmapData),
      concatMap(action =>
        this.dataService
          .getUsageChargingHeatmapData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(usageChargingHeatmapData => {
              return AnalyticsActions.setUsageChargingHeatmapData({
                usageChargingHeatmapData,
                terminate: action.terminate,
              });
            }),
            catchError(() =>
              of(
                AnalyticsActions.setUsageChargingHeatmapData({
                  usageChargingHeatmapData: null,
                  terminate: 'complete',
                })
              )
            )
          )
      )
    )
  );

  getUsageAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getUsageAnalyticsData),
      switchMap(action =>
        this.dataService.getUsageAnalyticsData(action.time_0, action.time_1, action.vehicles).pipe(
          map(usageAnalyticsData => AnalyticsActions.setUsageAnalyticsData({ usageAnalyticsData })),
          catchError(() =>
            of(
              AnalyticsActions.setUsageAnalyticsData({
                usageAnalyticsData: null,
              })
            )
          )
        )
      )
    )
  );

  getChargingAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getChargingAnalyticsData),
      switchMap(action =>
        this.dataService
          .getChargingAnalyticsData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(chargingAnalyticsData =>
              AnalyticsActions.setChargingAnalyticsData({ chargingAnalyticsData })
            ),
            catchError(() =>
              of(
                AnalyticsActions.setChargingAnalyticsData({
                  chargingAnalyticsData: null,
                })
              )
            )
          )
      )
    )
  );

  getGeofenceAlertsAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getGeofenceAlertsAnalyticsData),
      switchMap(action =>
        this.dataService
          .getGeofenceAlertsAnalyticsData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(geofenceAlertsAnalyticsData =>
              AnalyticsActions.setGeofenceAlertsAnalyticsData({ geofenceAlertsAnalyticsData })
            ),
            catchError(() =>
              of(
                AnalyticsActions.setGeofenceAlertsAnalyticsData({
                  geofenceAlertsAnalyticsData: null,
                })
              )
            )
          )
      )
    )
  );

  getVehicleRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleRanking),
      switchMap(action =>
        this.dataService
          .getVehicleRanking(
            action.dimension,
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(vehicleRanking => AnalyticsActions.setVehicleRanking({ vehicleRanking })),
            catchError(() => of(AnalyticsActions.setVehicleRanking({ vehicleRanking: null })))
          )
      )
    )
  );

  getVehicleAnalyticsDataDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleAnalyticsDataDateRange),
      switchMap(() =>
        this.dataService.getVehicleAnalyticsDataDateRange().pipe(
          map(dataDateRange =>
            AnalyticsActions.setVehicleAnalyticsDataDateRange({ dataDateRange })
          ),
          catchError(() =>
            of(AnalyticsActions.setVehicleAnalyticsDataDateRange({ dataDateRange: null }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: AnalyticsDataService,
    private store: Store<AnalyticsAppState>
  ) {}
}
