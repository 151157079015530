import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AggregatePipe } from './aggregate.pipe';
import { ValueFormatPipe } from './value-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AggregatePipe, ValueFormatPipe],
  exports: [AggregatePipe, ValueFormatPipe],
  providers: [DecimalPipe],
})
export class WidgetHelpersModule {}
