import { Injectable } from '@angular/core';
import { RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { WeekStartLocale } from '../core.models';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  /**
   * Navigation state container to keep up with user's journey through pages
   * and allow programmatic return to a referrer route or a reload or the current
   */
  public readonly navigationState = {
    currentRoute: new BehaviorSubject<RoutesRecognized | undefined>(undefined),
    previousRoute: new BehaviorSubject<RoutesRecognized | undefined>(undefined),
  };
  get referrerRoute$(): Observable<RoutesRecognized | undefined> {
    return this.navigationState.previousRoute.asObservable().pipe(take(1));
  }

  public readonly miToKm = 1.60934;
  private unit_multiplier: number = this.miToKm;
  public inKM$ = new BehaviorSubject<boolean>(true);
  // TODO: Sync this with user config
  getUnitMultiplier() {
    return this.unit_multiplier;
  }
  setUnitMultiplier(event: boolean) {
    this.unit_multiplier = event ? this.miToKm : 1;
    this.inKM$.next(event);
  }

  // TODO: Sync this with user config
  private _weekStart: WeekStartLocale = WeekStartLocale.SUNDAY;
  public readonly weekStartLocale$ = new BehaviorSubject<WeekStartLocale>(this._weekStart);
  getWeekStartLocale(): WeekStartLocale {
    return this._weekStart;
  }
  setWeekStartLocale(weekStart: WeekStartLocale): void {
    this._weekStart = weekStart;
    this.weekStartLocale$.next(this._weekStart);
  }

  public static readonly DAY_OF_WEEK_MAP = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  constructor() {
    this.setWeekStartLocale(WeekStartLocale.SUNDAY);
  }
}

// 0: Array [ "2023-03-02T00:00:00Z", 9 ]
// 1: Array [ "2023-03-03T00:00:00Z", 9 ]
// 2: Array [ "2023-03-04T00:00:00Z", 9 ]
// 3: Array [ "2023-03-05T00:00:00Z", 9 ]
// 4: Array [ "2023-03-06T00:00:00Z", 10 ]
// 5: Array [ "2023-03-07T00:00:00Z", 10 ]
// 6: Array [ "2023-03-08T00:00:00Z", 10 ]
// 7: Array [ "2023-03-09T00:00:00Z", 10 ]
// 8: Array [ "2023-03-10T00:00:00Z", 10 ]
// 9: Array [ "2023-03-13T00:00:00Z", 11 ]
// 10: Array [ "2023-03-14T00:00:00Z", 11 ]
// 11: Array [ "2023-03-15T00:00:00Z", 11 ]
// 12: Array [ "2023-03-16T00:00:00Z", 11 ]
// 13: Array [ "2023-03-17T00:00:00Z", 11 ]
// 14: Array [ "2023-03-18T00:00:00Z", 11 ]
// 15: Array [ "2023-03-19T00:00:00Z", 11 ]
// 16: Array [ "2023-03-20T00:00:00Z", 12 ]
// 17: Array [ "2023-03-21T00:00:00Z", 12 ]
// 18: Array [ "2023-03-22T00:00:00Z", 12 ]
