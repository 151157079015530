import { Injectable, SecurityContext } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOM_ICON_REGISTRY } from './icon-registry.static';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  /** Set of icon names per namespace */
  private readonly iconRegistry: {
    default: Set<string>;
    [key: string]: Set<string>;
  } = {
    default: new Set<string>(),
  };

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    for (const entry of CUSTOM_ICON_REGISTRY) this.register(entry.icon, entry.url);
  }

  public register(name: string, url: string, namespace: 'default' | string = 'default'): void {
    if (namespace === 'default' && this.iconRegistry['default'].has(name)) {
      console.error(
        // eslint-disable-next-line max-len
        `[Icon Registry] Cannot add icon with name "${name}" to the default scope as it is already defined`
      );
      return;
    }

    const safeUrl = this.domSanitizer.sanitize(SecurityContext.URL, url);
    if (!safeUrl) throw `[Icon Registry] Unsafe URL entry for icon "${name}"`;

    const resourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(safeUrl);

    if (namespace === 'default') this.matIconRegistry.addSvgIcon(name, resourceUrl);
    else this.matIconRegistry.addSvgIconInNamespace(namespace, name, resourceUrl);
  }
}
