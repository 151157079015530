import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionsPipe } from './has-permissions.pipe';

@NgModule({
  declarations: [HasPermissionsPipe],
  exports: [HasPermissionsPipe],
  imports: [CommonModule],
})
export class PermissionsModule {}
