import { createAction, props } from '@ngrx/store';
import { VehicleState } from '../../../shared/vehicle/vehicle.models';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  ChargingAnalyticsData,
  GeofenceAlertsData,
  VehicleRankingData,
  UsageAnalyticsData,
  VehicleInsightDimension,
  AnalyticsDataDateRange,
} from '../../analytics.models';

export const clearState = createAction('[Analytics] Clear State');

type GenericWidgetRequestBody = {
  time_0: Date;
  time_1: Date;
  compare_time_0?: Date;
  compare_time_1?: Date;
  vehicles: number[];
};

/* Meta Data */
export const getVehicleAnalyticsDataDateRange = createAction(
  '[Analytics] Get Vehicle Analytics Data Date Range'
);

export const setVehicleAnalyticsDataDateRange = createAction(
  '[Analytics] Set Vehicle Analytics Data Date Range',
  props<{ dataDateRange: AnalyticsDataDateRange | null }>()
);

/* Travel Analytics */
export const getVehicleTravelData = createAction(
  '[Analytics] Get Vehicle Travel Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleTravelData = createAction(
  '[Analytics] Set Vehicle Travel Data',
  props<{ travelData: WidgetDataResponse }>()
);

export const setVehicleTravelDataError = createAction(
  '[Analytics] Set Vehicle Analytics Travel Data Into Error State'
);

/* Activity Analytics */
export const getVehicleActivityData = createAction(
  '[Analytics] Get Vehicle Activity Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleActivityData = createAction(
  '[Analytics] Set Vehicle Activity Data',
  props<{ activityData: WidgetDataResponse | null }>()
);

/* Alert Analytics */
export const getVehicleAlertData = createAction(
  '[Analytics] Get Vehicle Alert Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleAlertData = createAction(
  '[Analytics] Set Vehicle Alert Data',
  props<{ alertData: WidgetDataResponse | null }>()
);

/* Charging Analytics */
export const getVehicleChargingData = createAction(
  '[Analytics] Get Vehicle Charging Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleChargingData = createAction(
  '[Analytics] Set Vehicle Charging Data',
  props<{ chargingData: WidgetDataResponse | null }>()
);

/* Vehicle Ranking supporting widget endpoints above */
export const getVehicleRanking = createAction(
  '[Analytics] Get Vehicle Ranking',
  props<{ dimension: VehicleInsightDimension } & GenericWidgetRequestBody>()
);
export const setVehicleRanking = createAction(
  '[Analytics] Set Vehicle Ranking',
  props<{ vehicleRanking: VehicleRankingData | null }>()
);

export const getUsageChargingHeatmapData = createAction(
  '[Analytics] Get Usage/Charging Heatmap Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[]; terminate?: 'complete' | 'terminate' }>()
);
export const setUsageChargingHeatmapData = createAction(
  '[Analytics] Set Usage/Charging Heatmap Data',
  props<{ usageChargingHeatmapData: VehicleState[] | null; terminate?: 'complete' | 'terminate' }>()
);

export const getUsageAnalyticsData = createAction(
  '[Analytics] Get Usage Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setUsageAnalyticsData = createAction(
  '[Analytics] Set Usage Analytics Data',
  props<{ usageAnalyticsData: UsageAnalyticsData | null }>()
);

export const getChargingAnalyticsData = createAction(
  '[Analytics] Get Charging Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setChargingAnalyticsData = createAction(
  '[Analytics] Set Charging Analytics Data',
  props<{ chargingAnalyticsData: ChargingAnalyticsData | null }>()
);

export const getGeofenceAlertsAnalyticsData = createAction(
  '[Analytics] Get GeoFence Alerts Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setGeofenceAlertsAnalyticsData = createAction(
  '[Analytics] Set GeoFence Alerts Analytics Data',
  props<{ geofenceAlertsAnalyticsData: GeofenceAlertsData[] | null }>()
);
