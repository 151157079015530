import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import { RealTimeMonitoringStoreService } from './real-time-monitoring-store.service';
import * as RealTimeMonitoringActions from './real-time-monitoring.actions';

@Injectable()
export class RealTimeMonitoringEffects {
  getVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealTimeMonitoringActions.getVehicleList),
      concatMap(() =>
        this.realTimeMonitoringService.getVehicleList().pipe(
          map(vehicleList => RealTimeMonitoringActions.setVehicleList({ vehicleList })),
          catchError(() => of(RealTimeMonitoringActions.setVehicleList({ vehicleList: undefined })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private realTimeMonitoringService: RealTimeMonitoringStoreService
  ) {}
}
