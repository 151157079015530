import { ActionReducerMap } from '@ngrx/store';
import { insightsGeneralReducer, InsightsGeneralState } from './general/insights.reducer';

export type InsightsAppState = {
  insightsGeneral: InsightsGeneralState;
};

export const insightsAppReducers: ActionReducerMap<InsightsAppState> = {
  insightsGeneral: insightsGeneralReducer,
};
