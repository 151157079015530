import { Action, createReducer, on } from '@ngrx/store';
import { VehicleAlertsResponse } from '../../../../monitoring/monitoring.models';
import { VehicleState } from '../../../../shared/vehicle/vehicle.models';
import * as HTActions from './historical-tracking.actions';

export type HistoricalTrackingState = {
  loadingStatus: {
    vehicleTrips: boolean;
    vehicleAlerts: boolean;
  };
  vehicleTrips?: VehicleState[] | null;
  vehicleAlerts?: VehicleAlertsResponse | null;
};

const initialState: HistoricalTrackingState = {
  loadingStatus: {
    vehicleTrips: false,
    vehicleAlerts: false,
  },
  vehicleTrips: undefined,
  vehicleAlerts: undefined,
};

const reducer = createReducer<HistoricalTrackingState>(
  initialState,
  // -------- Vehicle List
  on(HTActions.getVehicleTrips, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleTrips: true,
    },
  })),
  on(HTActions.setVehicleTrips, (state, { tripsStates }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleTrips: false,
    },
    vehicleTrips: tripsStates,
  })),
  on(HTActions.getVehicleHistoricalAlerts, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: true,
    },
  })),
  on(HTActions.setVehicleHistoricalAlerts, (state, { alerts }) => {
    return {
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        vehicleAlerts: false,
      },
      vehicleAlerts: alerts,
    };
  })
);

export function historicalTrackingReducer(
  state: HistoricalTrackingState | undefined,
  action: Action
): HistoricalTrackingState {
  return reducer(state, action);
}
export const historicalTrackingFeatureKey = 'historicalTracking';
