import { ZEVA_PAGES, ZEVA_PAGE_EXECUTE_PERMISSION, ZEVA_PAGE_VIEW_PERMISSION, ZevaSubsystems } from '../core.static';
import { NavigationElement } from './sidenav.models';

/* Array that determines the navigation menu */
export const DEFAULT_NAVIGATION: NavigationElement[] = [
  {
    name: ZevaSubsystems.Insights.name,
    link: '/' + ZevaSubsystems.Insights.slug,
    icon: 'speed',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['insights-main']],
  },
  {
    name: ZevaSubsystems.Analytics.name,
    link: '/' + ZevaSubsystems.Analytics.slug,
    icon: 'query_stats',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['analytics-vehicle']],
  },
  {
    name: ZevaSubsystems.Monitoring.name,
    link: '/' + ZevaSubsystems.Monitoring.slug,
    icon: 'directions_car',
    subPages: [
      {
        name: ZEVA_PAGES['monitoring-real-time-monitoring'].name,
        // eslint-disable-next-line max-len
        link: `/${ZevaSubsystems.Monitoring.slug}/${ZEVA_PAGES['monitoring-real-time-monitoring'].slug}`,
        icon: 'wifi',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['monitoring-real-time-monitoring']],
      },
      {
        name: ZEVA_PAGES['monitoring-historical-tracking'].name,
        // eslint-disable-next-line max-len
        link: `/${ZevaSubsystems.Monitoring.slug}/${ZEVA_PAGES['monitoring-historical-tracking'].slug}`,
        icon: 'route',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['monitoring-historical-tracking']],
      },
      {
        name: ZEVA_PAGES['monitoring-command-centre'].name,
        // eslint-disable-next-line max-len
        link: `/${ZevaSubsystems.Monitoring.slug}/${ZEVA_PAGES['monitoring-command-centre'].slug}`,
        icon: 'settings_remote',
        permissionsRequired: [ZEVA_PAGE_EXECUTE_PERMISSION['monitoring-command-centre']],
      },
    ],
  },
  {
    name: ZevaSubsystems.Admin.name,
    link: '/' + ZevaSubsystems.Admin.slug,
    icon: 'tune',
    subPages: [
      {
        name: ZEVA_PAGES['admin-account'].name,
        link: `/${ZevaSubsystems.Admin.slug}/${ZEVA_PAGES['admin-account'].slug}`,
        icon: 'business_center',
        permissionsRequired: [ZEVA_PAGE_EXECUTE_PERMISSION['admin-account']],
      },
      {
        name: ZEVA_PAGES['admin-profile'].name,
        link: `/${ZevaSubsystems.Admin.slug}/${ZEVA_PAGES['admin-profile'].slug}`,
        icon: 'manage_accounts',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['admin-profile']],
      },
      {
        name: ZEVA_PAGES['admin-fleet'].name,
        link: `/${ZevaSubsystems.Admin.slug}/${ZEVA_PAGES['admin-fleet'].slug}`,
        icon: 'garage',
        permissionsRequired: [ZEVA_PAGE_EXECUTE_PERMISSION['admin-fleet']],
      },
      {
        name: ZEVA_PAGES['monitoring-geofence-management'].name,
        // eslint-disable-next-line max-len
        link: `/${ZevaSubsystems.Admin.slug}/${ZEVA_PAGES['monitoring-geofence-management'].slug}`,
        icon: 'fence',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION['monitoring-geofence-management']],
      },
    ],
  },
];
