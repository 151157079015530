import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionResponse, SignInResponse } from './auth.models';
import { signInUrl, signOutUrl, csrfUrl, sessionUrl, refreshSessionUrl } from './auth.urls';

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  public getCSRFToken(): Observable<HttpResponseBase> {
    return this.httpClient.get(environment.apiUrl + csrfUrl, {
      observe: 'response',
      withCredentials: true,
    });
  }

  public getSession(): Observable<SessionResponse> {
    return this.httpClient.get<SessionResponse>(environment.apiUrl + sessionUrl, {
      withCredentials: true,
    });
  }

  public signIn(username: string, password: string, csrf: string): Observable<SignInResponse> {
    return this.httpClient.post<SignInResponse>(
      environment.apiUrl + signInUrl,
      {
        username,
        password,
      },
      {
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrf },
        withCredentials: true,
      }
    );
  }

  public signOut(): Observable<unknown> {
    return this.httpClient.get(environment.apiUrl + signOutUrl, { withCredentials: true });
  }

  public refreshSession(): Observable<unknown> {
    return this.httpClient.get(environment.apiUrl + refreshSessionUrl, { withCredentials: true });
  }
}
