import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, from, lastValueFrom, Observable, takeWhile, tap } from 'rxjs';
import { AppState } from '../../store/app.reducers';
import { getCSRFToken } from '../store/auth.actions';
import { selectCSRFToken } from '../store/auth.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handleRequest(req, next, 'cookie'));
  }

  private async handleRequest(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    method: 'jwt' | 'cookie'
  ): Promise<HttpEvent<unknown>> {
    switch (method) {
      case 'cookie': {
        if (req.method === 'GET') break;

        const csrfToken$ = await lastValueFrom(
          this.store.pipe(
            select(selectCSRFToken),
            takeWhile(token => token === undefined, true),
            tap(token => {
              if (!token) this.store.dispatch(getCSRFToken());
            }),
            first(token => token !== undefined)
          )
        );

        req = req.clone({
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-CSRFToken', csrfToken$!),
        });

        break;
      }
      case 'jwt': {
        break;
      }
    }

    return lastValueFrom(next.handle(req));
  }

  // private addCompanyReference(request: HttpRequest<any>): HttpRequest<any> {
  //   return request.clone({
  //     params: request.params.set('company', this.global.getCompany()),
  //   });
  // }
}
