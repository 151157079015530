import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, filter, first, map, Observable, of, Subscription, tap } from 'rxjs';
import { UserProfile } from '../../auth/utility/auth.models';
import { signOut } from '../../auth/store/auth.actions';
import { selectSession, selectUserProfile } from '../../auth/store/auth.selectors';
import { HeaderService } from './header.service';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() onSidenavButtonClick = new EventEmitter<void>();

  quickAccessTemplate?: TemplateRef<unknown>;
  private quickAccessItem$?: Subscription;

  user$?: Observable<UserProfile>;

  constructor(
    private readonly store: Store,
    private readonly headerService: HeaderService,
    public readonly sidenavService: SidenavService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.quickAccessItem$ = this.headerService.quickAccessItem$.subscribe(item => {
      if (item instanceof TemplateRef) this.quickAccessTemplate = item;
      else this.quickAccessTemplate = undefined;

      this.cdr.markForCheck();
    });

    this.user$ = this.store.select(selectUserProfile).pipe(
      filter(up => up !== undefined && up !== null),
      map(up => up!)
    );
  }

  ngOnDestroy(): void {
    this.quickAccessItem$?.unsubscribe();
  }

  onSignOut(): void {
    this.store.dispatch(signOut());
    this.store
      .pipe(
        select(selectSession),
        first(isAuthenticated => isAuthenticated === undefined || isAuthenticated === false),
        tap(() => {
          void this.router.navigate(['/auth/login']);
        }),
        catchError(err => {
          console.log('Unable to sign out. Because of the following:');
          console.error(err);

          return of();
        })
      )
      .subscribe();
  }

  toggleSidenav(): void {
    this.sidenavService.toggle();
  }
}
