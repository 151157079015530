import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationElement } from './sidenav.models';
import { DEFAULT_NAVIGATION } from './sidenav.static';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  /** Dynamic Navigation items */
  set navigationTemplate(template) {
    this._navigationTemplate = template !== undefined ? template : DEFAULT_NAVIGATION;
    this.navigationTemplate$.next(this._navigationTemplate);
  }
  get navigationTemplate() {
    return this._navigationTemplate;
  }
  private _navigationTemplate: NavigationElement[] | undefined = DEFAULT_NAVIGATION;
  public readonly navigationTemplate$ = new Subject<this['navigationTemplate']>();

  /** Dynamic extra content to be displayed under the main navigation */
  set extraMenu(template) {
    this._extraMenu = template;
    this.extraMenu$.next(this._extraMenu);
  }
  get extraMenu() {
    return this._extraMenu;
  }
  private _extraMenu: TemplateRef<unknown> | undefined;
  public readonly extraMenu$ = new Subject<this['extraMenu']>();

  /** Open/Close state management */
  set opened(opened: boolean) {
    this._opened = opened;
    this.toggled$.next(this._opened);
  }
  get opened() {
    return this._opened;
  }
  private _opened = false;

  private readonly toggled$ = new Subject<boolean>();
  public readonly onToggle = this.toggled$.asObservable();

  constructor() {}

  toggle(): void {
    this.opened = !this.opened;
  }

  ngOnDestroy(): void {
    this.navigationTemplate$.complete();
    this.extraMenu$.complete();
  }
}
