import { VehicleStates } from "../../real-time-monitoring.static";

/* All vehicle filters */
export enum VehicleFilters {
  ALL = 'all',
  ONLINE = 'online',
  PARKED = 'parked',
  MOVING = 'moving',
  CHARGING = 'charging',
  ALERT = 'alert',
  CUSTOM = 'custom',
}

/* Searchable vehicle attributes */
export enum QueryAttributes {
  VIN = 'vin',
  NAME = 'name',
  MODEL = 'model',
  COLOR = 'color',
}
