/* Rates at which different elements of the RTM map refresh (in milliseconds) */
export enum RtmRefreshRates {
  VEHICLE_LIST = 60_000, // Fetch vehicle list
  MAP_UPDATE = 10_000, // Update markers in map
}

/* Possible vehicle states */
export enum VehicleStates {
  ONLINE = 'online', // A vehicle that is online
  PARKED = 'parked', // A vehicle that is parked
  MOVING = 'moving', // A vehicle that is currently moving
  // eslint-disable-next-line max-len
  STOPPED = 'stopped', // A vehicle that is not currently moving but whose shift is in a moving position (eg. sitting in traffic)
  CHARGING = 'charging', // A vehicle that is charging
  ALERT = 'alert', // A vehicle that is currently triggering an alert
  ASLEEP = 'asleep', // A vehicle that is asleep (no real-time data)
}

export enum VehicleAlerts {
  BATTERY = 'low_battery',
  OVERCHARGE = 'overcharge',
  SPEED = 'speed',
  GEOFENCE = 'geofence',
}

/* Vehicle state icons */
export const VEHICLE_STATE_ICONS: {
  [state: string]: { icon: string; color: string };
  // [state in vehicleStates]: { icon: string; color: string };
} = {
  [VehicleStates.ONLINE]: { icon: 'online', color: '#6CA241' },
  [VehicleStates.PARKED]: { icon: 'parking', color: '#6a0dad' },
  [VehicleStates.MOVING]: { icon: 'moving', color: '#0064B4' },
  [VehicleStates.STOPPED]: { icon: 'pause', color: '#FA9C1B' },
  [VehicleStates.CHARGING]: { icon: 'charging', color: '#FFC843' },
  [VehicleStates.ALERT]: { icon: 'alerts', color: '#FF001B' },
  [VehicleStates.ASLEEP]: { icon: 'moon', color: '#808080' },
  speed: { icon: 'speed', color: '#000000' },
  custom: { icon: 'search', color: '#a5a5a5' },
};

export const VEHICLE_ALERT_ICONS: {
  [key in VehicleAlerts | string]: { icon: string; color: string };
} = {
  [VehicleAlerts.BATTERY]: { icon: 'battery_1_bar', color: '#4a4a4a' },
  [VehicleAlerts.OVERCHARGE]: { icon: 'battery_alert', color: '#4a4a4a' },
  [VehicleAlerts.GEOFENCE]: { icon: 'fmd_bad', color: '#4a4a4a' },
  [VehicleAlerts.SPEED]: { icon: 'speed', color: '#4a4a4a' },
};

/* Vehicle refresh rates  */
export const VEHICLE_REFRESH_RATES: {
  [state in VehicleStates | string]: number;
} = {
  [VehicleStates.PARKED]: 60_000, // Fetch data of parked vehicles
  [VehicleStates.MOVING]: 10_000, // Fetch data of moving vehicles
  [VehicleStates.STOPPED]: 5_000, // Fetch data of vehicles that recently stopped
  'filtered-out': 60_000, // Fetch data of vehicles that we filtered out
};
