import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Vehicle, VehicleState } from '../../../shared/vehicle/vehicle.models';
import { VehicleFilters } from './components/rtm-map-query-overlay/rtm-map-query-overlay.static';
import { VehicleDictionary, VehicleDataDTO } from './real-time-monitoring.models';
import { VehicleAlerts, VehicleStates } from './real-time-monitoring.static';
import { VehicleAlertsResponse } from '../../monitoring.models';
import * as moment from 'moment';

@Injectable()
export class RealTimeMonitoringDataService {
  /* Main vehicle data dictionary */
  private vehicles: VehicleDictionary = {};
  private vehiclesSubject = new BehaviorSubject<VehicleDictionary>(this.vehicles);
  public vehiclesObservable = this.vehiclesSubject.asObservable();

  /* Recently fetched individual vehicle data */
  private vehicleDataSubject = new Subject<VehicleDataDTO>();
  public vehicleDataObservable = this.vehicleDataSubject.asObservable();

  /* Selected vehicle filter */
  private vehicleFilter: VehicleFilters = VehicleFilters.ALL;
  public vehicleFilterSubject = new BehaviorSubject<VehicleFilters>(this.vehicleFilter);
  public vehicleFilterObservable = this.vehicleFilterSubject.asObservable();

  constructor() {}

  /**
   * Stores relevant data from rawVehicleList into the vehicles dictionary.
   * @param {VehiclesListDTO} rawVehicleList - The vehicle list as provided by the tesla API
   * @author Juan Corral
   */
  // public updateVehicleList(rawVehicleList: VehiclesListDTO): void {
  //   const vehicleList = rawVehicleList['response'] || [];

  //   for (const vehicle of vehicleList) {
  //     const id = vehicle['id_s'];

  //     if (id in this.vehicles) {
  //       if (vehicle.state === 'asleep') this.vehicles[id].states = [VehicleStates.ASLEEP];
  //       else if (this.vehicles[id].states.includes(VehicleStates.ASLEEP))
  //         this.vehicles[id].states = [];
  //     } else {
  //       this.vehicles[id] = {
  //         id: id,
  //         vin: vehicle['vin'],
  //         name: vehicle['display_name'],
  //         states: vehicle.state === 'asleep' ? [VehicleStates.ASLEEP] : [],
  //         filteredOut: false,
  //       };
  //     }
  //   }

  //   // Update in all components
  //   this.vehiclesSubject.next(this.vehicles);
  // }
  public updateVehicleList(rawVehicleList: Vehicle[]): void {
    for (const vehicle of rawVehicleList) {
      const id = vehicle.id;

      this.vehicles[id] = {
        ...vehicle,
        states: new Set<VehicleStates>().add(VehicleStates.ASLEEP),
        alerts: new Set<VehicleAlerts>(),
        filteredOut: false,
      };
    }
    this.vehiclesSubject.next(this.vehicles);
  }

  // /**
  //  * Stores relevant data from rawVehicleData into the vehicle's entry
  //  * of the vehicles dictionary.
  //  * @param {string} vehicleId - The id of the vehicle to update
  //  * @param {VehicleDataDTO} newData - The vehicle's data as provided by the tesla API
  //  * @author Juan Corral
  //  */
  // public updateVehicleData(vehicleId: string, newData: VehicleDataDTO): void {
  //   const currData = this.vehicles[vehicleId];

  //   // If there's no data, vehicle is asleep
  //   if (newData === undefined || newData.response === undefined || newData.response === null) {
  //     currData.states = [VehicleStates.ASLEEP];
  //     return;
  //   }

  //   currData.states = [VehicleStates.ONLINE];

  //   // Parse vehicle data
  //   const newVehicleData = newData.response,
  //     lat = newVehicleData.drive_state.latitude,
  //     lng = newVehicleData.drive_state.longitude,
  //     shiftState = newVehicleData.drive_state.shift_state,
  //     charging = newVehicleData.charge_state.charging_state,
  //     model = DataManager.capitalizeWords(
  //       newVehicleData.vehicle_config.car_type.replace('model', 'Model ')
  //     ),
  //     color = DataManager.capitalizeWords(newVehicleData.vehicle_config.exterior_color);

  //   // Update vehicle state
  //   if (shiftState === 'D' || shiftState === 'R') {
  //     currData.states.push(VehicleStates.MOVING);
  //     if (lat === currData.latitude && lng === currData.longitude)
  //       currData.states.push(VehicleStates.STOPPED);
  //   } else {
  //     currData.states.push(VehicleStates.PARKED);
  //     if (charging === 'Complete' || charging === 'Charging')
  //       currData.states.push(VehicleStates.CHARGING);
  //   }

  //   // Update location
  //   currData.latitude = lat;
  //   currData.longitude = lng;

  //   // Update attributes
  //   currData.model = model;
  //   currData.color = color;

  //   // Pass changes on to other components
  //   this.vehiclesSubject.next(this.vehicles);
  //   this.vehicleDataSubject.next(newData);
  // }

  /**
   * Updates relevant data from rawVehicleData into the vehicle's entry of the vehicles dictionary.
   * @param {VehicleState[]} vehicleData - List of vehicle latest state data
   * @author Anton Valeev
   */
  public updateVehicleData(vehicleData: VehicleState[]): void {
    for (const data of vehicleData) {
      const vehicle = this.vehicles[data.vehicle];

      // sanity check - theoretically should never be true
      if (!vehicle) {
        console.warn('Vehicle for data not found', data);
        throw `Vehicle for this data was not found.
          Check or re-run VehicleList query for vehicle#${data.vehicle}`;
      }

      // check if the last recorded state of a vehicle is no older that 1 minute,
      // otherwise mark the vehicle as ASLEEP
      if (moment(data.timestamp).isBefore(moment().subtract(1, 'minute'))) {
        vehicle.states = new Set<VehicleStates>().add(VehicleStates.ASLEEP);
        vehicle.lastState = data;
        continue;
      }

      vehicle.states = new Set<VehicleStates>().add(VehicleStates.ONLINE);

      // if vehicle's shift state is either in Drive or Reverse - mark the vehicle as MOVING
      if (data.shift_state === 'D' || data.shift_state === 'R') {
        vehicle.states.add(VehicleStates.MOVING);

        // check if the vehicle has changed position since last data update,
        // if not mark the vehicle as STOPPED
        if (
          vehicle.lastState?.latitude === data.latitude &&
          vehicle.lastState?.longitude === data.longitude
        )
          vehicle.states.add(VehicleStates.STOPPED);
      } else {
        vehicle.states.add(VehicleStates.PARKED);

        // check if the vehicle's is charging, if it is charging - mark the vehicle as CHARGING
        if (data.is_charging) vehicle.states.add(VehicleStates.CHARGING);
      }

      // update vehicle's cached data to match with the new set
      vehicle.lastState = data;
    }

    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Updates the vehicle's filtered status based on the provided filter
   * @param {string} vehicleId - The id of the vehicle to update
   * @param {VehicleFilter} filter - The filter that id currently selected
   * @param {string[]} customList - Array of vehicle id's to include
   *   (only taken into account on 'custom' filter)
   * @author Juan Corral
   */
  public updateVehicleFilteredStatus(
    vehicleId: string,
    filter: VehicleFilters,
    customList: string[]
  ): void {
    const vehicle = this.vehicles[vehicleId];

    // Determine if marker has to be shown or hidden
    let show = false;
    switch (filter) {
      case VehicleFilters.ALL:
        show = true;
        break;
      case VehicleFilters.CUSTOM:
        if (customList.includes(vehicleId)) show = true;
        break;
      default: // Casting for TS to understand
        if (vehicle.states.has(<VehicleStates>(<unknown>filter))) show = true;
        break;
    }

    if (show) vehicle.filteredOut = false;
    else vehicle.filteredOut = true;

    // Update in all components
    this.vehiclesSubject.next(this.vehicles);
  }

  public updateVehicleAlerts(alerts: VehicleAlertsResponse): void {
    if (Object.keys(this.vehicles).length === 0) return;

    for (const alert of alerts) {
      this.vehicles[alert.vehicle].states.add(VehicleStates.ALERT);
      this.vehicles[alert.vehicle].alerts.add(alert.alert_type);
    }

    // for (const alert of alerts.battery_alerts) {
    //   this.vehicles[alert.vehicle].alerts.add(VehicleAlerts.BATTERY);
    //   this.vehicles[alert.vehicle].states.add(VehicleStates.ALERT);
    // }

    // for (const alert of alerts.speed_alerts) {
    //   this.vehicles[alert.vehicle].alerts.add(VehicleAlerts.SPEED);
    //   this.vehicles[alert.vehicle].states.add(VehicleStates.ALERT);
    // }

    // for (const alert of alerts.geofence_alerts) {
    //   this.vehicles[alert.vehicle].alerts.add(VehicleAlerts.GEOFENCE);
    //   this.vehicles[alert.vehicle].states.add(VehicleStates.ALERT);
    // }

    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Changes the selected vehicle filter to filter
   * @param {VehicleFilter} - The new selected filter
   */
  public changeVehicleFilter(filter: VehicleFilters): void {
    this.vehicleFilter = filter;
    this.vehicleFilterSubject.next(this.vehicleFilter);
  }
}
