import {
  Component,
  // ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  PipeTransform,
  Pipe,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, filter, map, of, startWith } from 'rxjs';

import { DEFAULT_NAVIGATION } from './sidenav.static';
import { NavigationElement } from './sidenav.models';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  navigationPages = DEFAULT_NAVIGATION;
  navigationPages$?: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    public readonly sidenav: SidenavService
  ) {}

  ngOnInit(): void {
    // attempt at generating dynamic menu out of sidenav service
    // this.navigationPages$ = this.sidenav.navigationTemplate$
    //   .asObservable()
    //   .pipe(tap(navTemplate => (this.navigationPages = navTemplate ?? DEFAULT_NAVIGATION)))
    //   .subscribe();
  }

  ngOnDestroy(): void {
    this.navigationPages$?.unsubscribe();
  }

  /**
   * Navigates to the page clicked
   * @param {NavigationElement} page
   */
  handleLinkClick(page: NavigationElement): void {
    if (page.disabled || !page.link) return;

    this.deactivateAllLinks();
    page.active = true;

    void this.router.navigateByUrl(page.link);
    this.changeDetector.markForCheck();
  }

  private deactivateAllLinks(): void {
    this.navigationPages.forEach(page => this.deactivateLink(page));
  }

  private deactivateLink(page: NavigationElement): void {
    page.active = false;
    page.subPages?.forEach(subPage => this.deactivateLink(subPage));
  }
}

/**
 * Sidenav domestic Pipe that checks if a NavigationElement is currently active
 * by comparing the `link` member of the element with the current Router URL
 */
@Pipe({
  name: 'isActive',
})
export class IsPageActivePipe implements PipeTransform {
  constructor(private readonly router: Router) {}

  transform(page: NavigationElement): Observable<boolean> {
    if (!page) return of(false);

    return this.router.events.pipe(
      // listen to router events emitted at the navigation end only
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      // start with the current router state,
      // since first NavigationEnd event emits before this gets called on the very first time
      startWith(this.router),
      map(() => {
        return (
          // check if the page is active
          this.checkActive(page) ||
          // or if the page has children and one of those children is active
          (page.subPages !== undefined &&
            page.subPages.length > 0 &&
            page.subPages.some(subPage => this.checkActive(subPage)))
        );
      })
    );
  }

  private checkActive(page: NavigationElement): boolean {
    return this.router.url === page.link;
  }
}
