import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InsightsDataService } from './insights-data.service';
import * as InsightsActions from './insights.actions';

@Injectable()
export class InsightsGeneralEffects {
  getUsageInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getUsageInsights),
      switchMap(action =>
        this.dataService.getUsageInsights(action.payload).pipe(
          map(usageInsights => InsightsActions.setUsageInsights({ usageInsights })),
          catchError(() => of(InsightsActions.setUsageInsights({ usageInsights: null })))
        )
      )
    )
  );

  getMileageRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getMileageRanking),
      switchMap(action =>
        this.dataService.getMileageRanking(action.payload).pipe(
          map(mileageRanking => InsightsActions.setMileageRanking({ mileageRanking })),
          catchError(() => of(InsightsActions.setMileageRanking({ mileageRanking: null })))
        )
      )
    )
  );

  getActivityInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getActivityInsights),
      switchMap(action =>
        this.dataService.getActivityInsights(action.payload).pipe(
          map(activityInsights => InsightsActions.setActivityInsights({ activityInsights })),
          catchError(() => of(InsightsActions.setActivityInsights({ activityInsights: null })))
        )
      )
    )
  );

  getChargingLevelsInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getChargingLevelsInsights),
      switchMap(action =>
        this.dataService.getChargingLevelsInsights(action.payload).pipe(
          map(chargingLevels => InsightsActions.setChargingLevelsInsights({ chargingLevels })),
          catchError(() => of(InsightsActions.setChargingLevelsInsights({ chargingLevels: null })))
        )
      )
    )
  );

  getEnergyConsumptionRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getEnergyConsumptionRanking),
      switchMap(action =>
        this.dataService.getEnergyConsumptionRanking(action.payload).pipe(
          map(energyConsumption =>
            InsightsActions.setEnergyConsumptionRanking({ energyConsumption })
          ),
          catchError(() =>
            of(InsightsActions.setEnergyConsumptionRanking({ energyConsumption: null }))
          )
        )
      )
    )
  );

  getAlertEventsInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getAlertEventsInsights),
      switchMap(action =>
        this.dataService.getAlertEventsInsights(action.payload).pipe(
          map(alertInsights => InsightsActions.setAlertEventsInsights({ alertInsights })),
          catchError(() => of(InsightsActions.setAlertEventsInsights({ alertInsights: null })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: InsightsDataService) {}
}
