<!-- <div class="sidenav-logo-container">
  <img src="../../../assets/zeva-logo.png" class="sidenav-logo" />
</div> -->

<div class="sidenav-container">
  <mat-accordion class="menu-holder" displayMode="flat">
    <ng-container *ngFor="let item of navigationPages">
      <ng-container
        *ngTemplateOutlet="pageButtonTemplate; context: { page: item, level: 0 }"
      ></ng-container>
    </ng-container>
  </mat-accordion>

  <div class="extras" *ngIf="sidenav.extraMenu">
    <mat-divider></mat-divider>
    <ng-container *ngTemplateOutlet="sidenav.extraMenu"></ng-container>
  </div>
</div>

<ng-template #pageButtonTemplate let-page="page" let-level="level">
  <mat-expansion-panel
    *ngIf="page.visible !== false"
    class="menu-item"
    [ngClass]="{
      active: page.subPages === undefined && page | isActive | async,
      disabled: page.disabled || !(page.permissionsRequired | hasPermissions | async)
    }"
    [expanded]="page.subPages && page | isActive | async"
    [disabled]="
      page.disabled || page.subPages === undefined || page.disabled || !(page.permissionsRequired
        | hasPermissions
        | async)
    "
    [hideToggle]="page.subPages === undefined"
  >
    <!-- Page title and icon -->
    <mat-expansion-panel-header
      class="link"
      [ngStyle]="{ paddingLeft: 1 + 2 * level + 'rem' }"
      (click)="page.subPages === undefined && handleLinkClick(page)"
      expandedHeight="48px"
    >
      <mat-panel-title class="title">
        <mat-icon *ngIf="page.icon !== undefined" class="material-icons-rounded">{{
          page.icon
        }}</mat-icon>
        <span class="menu-link-title">{{ page.name }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Sub pages -->
    <mat-accordion
      displayMode="flat"
      *ngIf="page.subPages !== undefined"
      class="sub-menu-container"
    >
      <ng-container *ngFor="let subPage of page.subPages">
        <ng-container
          *ngTemplateOutlet="pageButtonTemplate; context: { page: subPage, level: level + 1 }"
        ></ng-container>
      </ng-container>
    </mat-accordion>
  </mat-expansion-panel>
</ng-template>
