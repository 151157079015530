import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { filter, interval, Subscription, tap } from 'rxjs';
import { AuthService } from '../../auth/utility/auth.service';
import { selectSession } from '../../auth/store/auth.selectors';
import { AppState } from '../../store/app.reducers';
import { IdleCheckService } from './idle-check.service';
import {
  IdleWarningDialogComponent,
  IdleWarningDialogResponse,
} from './idle-warning-dialog/idle-warning-dialog.component';
import { signOut } from '../../auth/store/auth.actions';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-idle-watch',
  template: '',
  styleUrls: ['./idle-watch.component.scss'],
})
export class IdleWatchComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  // [14 minutes (7:59 hours for dev)] after how long to prompt an idle warning dialog
  private readonly promptIdleAfter = environment.production ? 60 * 14 : 60 * 60 * 8 - 1;

  private whenAuthenticated$?: Subscription;
  private refresh$?: Subscription;
  private idle$?: Subscription;

  private dialogRef?: MatDialogRef<IdleWarningDialogComponent, IdleWarningDialogResponse>;

  constructor(
    private idleService: IdleCheckService,
    private store: Store<AppState>,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.whenAuthenticated$ = this.store
      .pipe(
        select(selectSession),
        tap(isAuthenticated => {
          if (isAuthenticated) this.start();
          else this.stop();
        })
      )
      .subscribe();
  }

  private start(): void {
    this.idle$ = this.idleService
      .watch(this.promptIdleAfter)
      .pipe(
        filter(isIdle => isIdle && !this.dialogRef),
        tap(isIdle => {
          if (isIdle && !this.dialogRef) {
            this.dialogRef = this.dialog.open(IdleWarningDialogComponent, {
              width: '30rem',
              hasBackdrop: true,
            });

            this.dialogRef.afterClosed().subscribe(result => {
              if (result === IdleWarningDialogResponse.EXIT) {
                this.stop();
                this.store.dispatch(signOut());
                void this.router.navigate(['auth']);
              }

              this.dialogRef = undefined;
            });
          }
        })
      )
      .subscribe();
    // refresh session every `this.promptIdleAfter` minutes before it expires
    this.refresh$ = interval(this.promptIdleAfter).subscribe(() => this.auth.refreshSession());
  }

  private stop(): void {
    this.idle$?.unsubscribe();
    this.refresh$?.unsubscribe();
    this.idleService.dispose();
  }

  ngOnDestroy(): void {
    this.whenAuthenticated$?.unsubscribe();
    this.stop();
  }
}
