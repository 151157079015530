import { Action, createReducer, on } from '@ngrx/store';
import { VehicleState } from '../../../shared/vehicle/vehicle.models';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  AnalyticsDataDateRange,
  ChargingAnalyticsData,
  GeofenceAlertsData,
  UsageAnalyticsData,
  VehicleRankingData,
} from '../../analytics.models';

import * as AnalyticsActions from './analytics.actions';
import { Loadable } from '../../../shared/loading-state/loadable';
import { cloneDeep } from 'lodash';

export type AnalyticsGeneralState = {
  loadingStatus: {
    activityData: boolean;
    alertData: boolean;
    chargingData: boolean;
    usageChargingHeatmapData: boolean;
    usageAnalyticsData: boolean;
    chargingAnalyticsData: boolean;
    geofenceAlertsAnalyticsData: boolean;
    vehicleRanking: boolean;
    dataDateRange: boolean;
  };
  travelData: Loadable<WidgetDataResponse>;
  activityData?: WidgetDataResponse | null;
  alertData?: WidgetDataResponse | null;
  chargingData?: WidgetDataResponse | null;
  usageChargingHeatmapData?: VehicleState[][] | null;
  usageAnalyticsData?: UsageAnalyticsData | null;
  chargingAnalyticsData?: ChargingAnalyticsData | null;
  geofenceAlertsAnalyticsData?: GeofenceAlertsData[] | null;
  vehicleRanking?: VehicleRankingData | null;
  dataDateRange?: AnalyticsDataDateRange | null;
};

const initialState: AnalyticsGeneralState = {
  loadingStatus: {
    activityData: false,
    alertData: false,
    chargingData: false,
    usageChargingHeatmapData: false,
    usageAnalyticsData: false,
    chargingAnalyticsData: false,
    geofenceAlertsAnalyticsData: false,
    vehicleRanking: false,
    dataDateRange: false,
  },
  travelData: new Loadable(),
  activityData: undefined,
  alertData: undefined,
  chargingData: undefined,
  usageChargingHeatmapData: undefined,
  usageAnalyticsData: undefined,
  chargingAnalyticsData: undefined,
  geofenceAlertsAnalyticsData: undefined,
  vehicleRanking: undefined,
  dataDateRange: undefined,
};

const reducer = createReducer<AnalyticsGeneralState>(
  initialState,
  // Reset State to initial
  on(AnalyticsActions.clearState, () => ({
    ...initialState,
  })),
  // Travel Widget
  on(AnalyticsActions.getVehicleTravelData, state => ({
    ...state,
    travelData: state.travelData.loadingCopy(true),
  })),
  on(AnalyticsActions.setVehicleTravelData, (state, { travelData }) => ({
    ...state,
    travelData: new Loadable(cloneDeep(travelData), false),
  })),
  on(AnalyticsActions.setVehicleTravelDataError, state => ({
    ...state,
    travelData: new Loadable<WidgetDataResponse>(undefined, false, 'Unknown Error'),
  })),
  // Activity Widget
  on(AnalyticsActions.getVehicleActivityData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityData: true,
    },
  })),
  on(AnalyticsActions.setVehicleActivityData, (state, { activityData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityData: false,
    },
    activityData,
  })),
  // Alert Widget
  on(AnalyticsActions.getVehicleAlertData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertData: true,
    },
  })),
  on(AnalyticsActions.setVehicleAlertData, (state, { alertData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertData: false,
    },
    alertData,
  })),
  // Charging Widget
  on(AnalyticsActions.getVehicleChargingData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingData: true,
    },
  })),
  on(AnalyticsActions.setVehicleChargingData, (state, { chargingData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingData: false,
    },
    chargingData,
  })),
  // Usage/Charging Heatmap Data
  on(AnalyticsActions.getUsageChargingHeatmapData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageChargingHeatmapData: true,
    },
  })),
  on(
    AnalyticsActions.setUsageChargingHeatmapData,
    (state, { usageChargingHeatmapData, terminate }) => {
      let newData;
      if (usageChargingHeatmapData !== null) {
        if (state.usageChargingHeatmapData)
          newData = [...state.usageChargingHeatmapData, usageChargingHeatmapData];
        else newData = [usageChargingHeatmapData];
      }

      const newLoading = {
        ...state.loadingStatus,
        usageChargingHeatmapData:
          terminate !== undefined ? false : state.loadingStatus.usageChargingHeatmapData,
      };

      return {
        ...state,
        loadingStatus: newLoading,
        usageChargingHeatmapData: newData,
      };
    }
  ),
  // Usage Analytics Data
  on(AnalyticsActions.getUsageAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setUsageAnalyticsData, (state, { usageAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageAnalyticsData: false,
    },
    usageAnalyticsData,
  })),
  // Charging Analytics Data
  on(AnalyticsActions.getChargingAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setChargingAnalyticsData, (state, { chargingAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingAnalyticsData: false,
    },
    chargingAnalyticsData,
  })),
  // Geofence Alerts Analytics Data
  on(AnalyticsActions.getGeofenceAlertsAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceAlertsAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setGeofenceAlertsAnalyticsData, (state, { geofenceAlertsAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceAlertsAnalyticsData: false,
    },
    geofenceAlertsAnalyticsData,
  })),
  // Vehicle Ranking
  on(AnalyticsActions.getVehicleRanking, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRanking: true,
    },
  })),
  on(AnalyticsActions.setVehicleRanking, (state, { vehicleRanking }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRanking: false,
    },
    vehicleRanking,
  })),
  // Metadata
  on(AnalyticsActions.getVehicleAnalyticsDataDateRange, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      dataDateRange: true,
    },
  })),
  on(AnalyticsActions.setVehicleAnalyticsDataDateRange, (state, { dataDateRange }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      dataDateRange: false,
    },
    dataDateRange,
  }))
);

export function analyticsGeneralReducer(
  state: AnalyticsGeneralState | undefined,
  action: Action
): AnalyticsGeneralState {
  return reducer(state, action);
}
export const analyticsGeneralFeatureKey = 'analyticsGeneral';
