import { ActionReducerMap } from '@ngrx/store';
import { adminGeneralReducer, AdminGeneralState } from './general/admin.reducer';

export type AdminAppState = {
  adminGeneral: AdminGeneralState;
};

export const adminAppReducers: ActionReducerMap<AdminAppState> = {
  adminGeneral: adminGeneralReducer,
};
