import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderQuickAccessDirective } from './quick-access.directive';

@NgModule({
  declarations: [HeaderQuickAccessDirective],
  imports: [CommonModule],
  exports: [HeaderQuickAccessDirective],
})
export class HeaderQuickAccessModule {}
