import { ActionReducerMap } from '@ngrx/store';
import {
  vehicleAnalyticsReducer,
  VehicleAnalyticsState,
} from '../pages/vehicle/store/vehicle-analytics.reducer';
import { analyticsGeneralReducer, AnalyticsGeneralState } from './general/analytics.reducer';

export type AnalyticsAppState = {
  analyticsGeneral: AnalyticsGeneralState;
  vehicleAnalytics: VehicleAnalyticsState;
};

export const analyticsAppReducers: ActionReducerMap<AnalyticsAppState> = {
  analyticsGeneral: analyticsGeneralReducer,
  vehicleAnalytics: vehicleAnalyticsReducer,
};
