import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, pairwise, Subscription, tap } from 'rxjs';
import { getSession } from './auth/store/auth.actions';
import { AuthService } from './auth/utility/auth.service';
import { IconRegistryService } from './core/icon-registry/icon-registry.service';
import { GlobalService } from './core/services/global.service';
import { AppState } from './store/app.reducers';

export const APP_VERSION = '1.0.6';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ZEVA';
  private title$: Subscription | undefined;

  private routerEvents$: Subscription | undefined;

  private idle$: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store<AppState>,
    private global: GlobalService,
    private iconRegistry: IconRegistryService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getSession());

    /** extract router event with currently opened page's data from the route setup */
    const title$ = this.router.events.pipe(
      // accept only emissions that happen at the end of navigation sequence
      filter(event => event instanceof NavigationEnd),
      // eslint-disable-next-line max-len
      // pass on the currently active route (Angular stores reference to the route in ActivatedRoute injectable)
      map(() => this.activatedRoute),
      map(route => {
        // loop through route children to find last activated route
        while (route.firstChild) route = route.firstChild;
        return route; // last activated route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data), // pull path's data containing 'title'
      tap(event => {
        // set returned path's title
        // eslint-disable-next-line prettier/prettier
        this.title = `${ event['title'] as string } - ZEVA`;
        this.titleService.setTitle(this.title);
      })
    );
    this.title$ = title$.subscribe();

    /** collect and store immediate navigation to retain previous and current routes */
    this.routerEvents$ = this.router.events
      .pipe(
        filter(evt => evt instanceof RoutesRecognized),
        pairwise(),
        tap(events => {
          this.global.navigationState.previousRoute.next(events[0] as RoutesRecognized);
          this.global.navigationState.currentRoute.next(events[1] as RoutesRecognized);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.title$?.unsubscribe();
    this.routerEvents$?.unsubscribe();
    this.idle$?.unsubscribe();
  }
}
