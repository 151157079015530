import { animate, query, style, transition, trigger } from '@angular/animations';
import { ZevaPageDictionary, ZevaPageSelector, ZevaSubsystemsInterface } from './core.models';

/** Time format that will be expected as an accurate timestamp input for ZEVA backend */
export const ZEVA_TIMESTAMP_OUTPUT = 'YYYY-MM-DDTHH:mm:ssZ';

/** Time format for simple date display without time */
export const ZEVA_DATE_DISPLAY = 'YYYY-MM-DD';
/** Time format for simple date display with hours and minutes */
export const ZEVA_DATETIME_DISPLAY = 'YYYY-MM-DD HH:mm';
/** Time format for simple date display with hours, minutes and seconds */
export const ZEVA_TIMESTAMP_DISPLAY = 'YYYY-MM-DD HH:mm:ss';
/** Time format for short Month display with full Year and short month */
export const ZEVA_DATE_MONTH_SHORT = 'YYYY, MMM';

/**
 * Default format for any displayable numeric values. Use with Angular's common DecimalPipe.
 * @example
 * //* In HTML Template
 * {{ 9999999 | number: ZEVA_NUMBER_FORMAT }},
 *
 * //* In TS Component
 * constructor(private decimalPipe: DecimalPipe) {}
 *
 * member() {
 *   this.decimalPipe.transform(9999999, ZEVA_NUMBER_FORMAT);
 * }
 */
export const ZEVA_NUMBER_FORMAT = '0.0-2';

/**
 * Text placeholder for values that are either undefined, null or simply invalid
 * to be displayed normally
 */
export const ZEVA_DATA_PLACEHOLDER = '--';

export type ZevaTimeDisplayFormat =
  | typeof ZEVA_DATE_DISPLAY
  | typeof ZEVA_DATETIME_DISPLAY
  | typeof ZEVA_TIMESTAMP_DISPLAY;

/* Information of all Zeva subsystems */
export const ZevaSubsystems: ZevaSubsystemsInterface = {
  Insights: {
    id: 1,
    name: $localize`:Subsystem Name|Name for subsystem with ID 1@@subsystem-id-1:Insights`,
    slug: 'insights',
  },
  Analytics: {
    id: 2,
    name: $localize`:Subsystem Name|Name for subsystem with ID 2@@subsystem-id-2:Analytics`,
    slug: 'analytics',
  },
  Monitoring: {
    id: 3,
    name: $localize`:Subsystem Name|Name for subsystem with ID 3@@subsystem-id-3:Monitoring`,
    slug: 'monitoring',
  },
  Admin: {
    id: 4,
    name: $localize`:Subsystem Name|Name for subsystem with ID 4@@subsystem-id-4:Admin`,
    slug: 'admin',
  },
};

/* Information of all Zeva pages */
export const ZEVA_PAGES: ZevaPageDictionary = {
  // Insights
  [ZevaPageSelector.INSIGHTS]: {
    id: 6,
    name: $localize`:Page Name|Name for page with ID 6@@page-id-6:Insights`,
    slug: 'insights',
  },
  // Analytics
  [ZevaPageSelector.ANALYTICS_VEHICLE]: {
    id: 5,
    name: $localize`:Page Name|Name for page with ID 5@@page-id-5:Analytics`,
    slug: 'vehicle',
  },
  // Monitoring
  [ZevaPageSelector.MONITORING_RTM]: {
    id: 1,
    name: $localize`:Page Name|Name for page with ID 1@@page-id-1:Real-Time Monitoring`,
    slug: 'real-time-monitoring',
  },
  [ZevaPageSelector.MONITORING_HT]: {
    id: 2,
    name: $localize`:Page Name|Name for page with ID 2@@page-id-2:Historical Tracking`,
    slug: 'historical-tracking',
  },
  [ZevaPageSelector.MONITORING_CC]: {
    id: 9,
    name: $localize`:Page Name|Name for page with ID 9@@page-id-9:Command Centre`,
    slug: 'command-centre',
  },
  // Admin
  [ZevaPageSelector.ADMIN_ACCOUNT]: {
    id: 10,
    name: $localize`:Page Name|Name for page with ID 10@@page-id-10:Account`,
    slug: 'account',
  },
  [ZevaPageSelector.ADMIN_PROFILE]: {
    id: 7,
    name: $localize`:Page Name|Name for page with ID 7@@page-id-7:Profile`,
    slug: 'profile',
  },
  [ZevaPageSelector.ADMIN_FLEET]: {
    id: 8,
    name: $localize`:Page Name|Name for page with ID 8@@page-id-7:Fleet`,
    slug: 'fleet',
  },
  [ZevaPageSelector.MONITORING_GM]: {
    id: 3,
    name: $localize`:Page Name|Name for page with ID 3@@page-id-3:GeoFences`,
    slug: 'geofence-management',
  },
};

export const ZEVA_PAGE_VIEW_PERMISSION: { [key in ZevaPageSelector]: string } = {
  [ZevaPageSelector.INSIGHTS]: 'client.app_view_insights',
  [ZevaPageSelector.ANALYTICS_VEHICLE]: 'client.app_view_analytics',
  [ZevaPageSelector.MONITORING_RTM]: 'client.app_view_monitoring_real_time_monitoring',
  [ZevaPageSelector.MONITORING_HT]: 'client.app_view_monitoring_historical_tracking',
  [ZevaPageSelector.MONITORING_CC]: 'client.app_view_monitoring_command_centre',
  [ZevaPageSelector.ADMIN_PROFILE]: 'client.app_view_admin_profile',
  [ZevaPageSelector.ADMIN_FLEET]: 'client.app_view_admin_fleet',
  [ZevaPageSelector.MONITORING_GM]: 'client.app_view_admin_geofences',
  [ZevaPageSelector.ADMIN_ACCOUNT]: 'client.app_view_admin_fleet', // TODO: Replace this with actual Account access pem
};
export const ZEVA_PAGE_EXECUTE_PERMISSION: { [key in ZevaPageSelector]: string } = {
  [ZevaPageSelector.INSIGHTS]: 'client.app_execute_insights',
  [ZevaPageSelector.ANALYTICS_VEHICLE]: 'client.app_execute_analytics',
  [ZevaPageSelector.MONITORING_RTM]: 'client.app_execute_monitoring_real_time_monitoring',
  [ZevaPageSelector.MONITORING_HT]: 'client.app_execute_monitoring_historical_tracking',
  [ZevaPageSelector.MONITORING_CC]: 'client.app_execute_monitoring_command_centre',
  [ZevaPageSelector.ADMIN_PROFILE]: 'client.app_execute_admin_profile',
  [ZevaPageSelector.ADMIN_FLEET]: 'client.app_execute_admin_fleet',
  [ZevaPageSelector.MONITORING_GM]: 'client.app_execute_admin_geofences',
  [ZevaPageSelector.ADMIN_ACCOUNT]: 'client.app_execute_admin_fleet', // TODO: Replace this with actual Account access pem
};

/* Values */
export const NAString = 'N/A';
export const NoNumericDataString = '--';

export const TESLA_MODEL_TO_IMAGE_MAPPING: { [key: string]: string } = {
  // Model 3
  'Model 3': '/assets/vehicles/tesla/ModelThree.png',
  Model3: '/assets/vehicles/tesla/ModelThree.png',
  // Model S and variants
  'Model S': '/assets/vehicles/tesla/ModelS.png',
  'Model S2': '/assets/vehicles/tesla/ModelS.png',
  ModelS: '/assets/vehicles/tesla/ModelS.png',
  ModelS2: '/assets/vehicles/tesla/ModelS.png',
  Lychee: '/assets/vehicles/tesla/ModelS.png',
  // Model X and variants
  'Model X': '/assets/vehicles/tesla/ModelX.png',
  ModelX: '/assets/vehicles/tesla/ModelX.png',
  Tamarind: '/assets/vehicles/tesla/ModelX.png',
  // Model Y
  'Model Y': '/assets/vehicles/tesla/ModelY.png',
  ModelY: '/assets/vehicles/tesla/ModelY.png',
};

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({ position: 'absolute', width: '100%', opacity: 0 }), {
      optional: true,
    }),
    query(
      ':enter',
      [
        style({ transform: 'translateY(66px)' }),
        animate(
          '0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ],
      { optional: true }
    ),
    // /* 2 */ group([  // block executes in parallel
    //   query(':enter', [
    //     style({ transform: 'translateX(100%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    //   ], { optional: true }),
    //   query(':leave', [
    //     style({ transform: 'translateX(0%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
    //   ], { optional: true }),
    // ])
  ]),
]);

export const TRIP_LINE_COLOR_SET = [
  '#99e2b4',
  '#88d4ab',
  '#78c6a3',
  '#67b99a',
  '#56ab91',
  '#469d89',
  '#358f80',
  '#248277',
  '#14746f',
  '#036666',
];
export const TRIP_LINE_COLORS = {
  active: TRIP_LINE_COLOR_SET[4],
  inactive: '#b2d7cc',
  alert: '#ff001b90',
};

// ------------ ECharts Config ------------

// export const CHART_COLORS = [
//   '#006438',
//   '#05844d',
//   '#0e9559',
//   '#19a866',
//   '#21b770',
//   '#4ec286',
//   '#71ce9b',
//   '#9cdbb7',
// ];
export const CHART_COLORS = [
  '#205DB9',
  '#4C7DC7',
  '#7A9ED5',
  '#A5BEE3',
  '#C1D2EC',
  '#67BFAF',
  '#143987',
  '#0B8BB1',
  '#A1D0C7',
  // '#DE4D4D',
  // '#FF7E79',
];

export const CHART_TEXT_STYLE = {
  fontFamily:
    // eslint-disable-next-line max-len
    // 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    '"Jost", "Helvetica Neue", sans-serif',
  fontSize: 11.2,
  fontWeight: 'normal',
};

export const ZEVA_DEFAULT_CHART_OPTIONS = {
  color: CHART_COLORS,
  textStyle: CHART_TEXT_STYLE,
  length: { type: 'scroll' },
};
