import { Action, createReducer, on } from '@ngrx/store';
import { UserProfile } from '../utility/auth.models';
import * as AuthActions from './auth.actions';

export type AuthState = {
  loadingStatus: {
    session: boolean;
    signIn: boolean;
  };
  isAuthenticated?: boolean;
  csrfToken?: string | null;
  userProfile?: UserProfile | null;
};

const initialState: AuthState = {
  loadingStatus: {
    session: false,
    signIn: false,
  },
};

const reducer = createReducer<AuthState>(
  initialState,
  on(AuthActions.getSession, state => state),
  on(AuthActions.setSession, (state, { session }) => {
    const newState = {
      ...state,
      isAuthenticated: session.isAuthenticated,
    };
    if (session.user) newState.userProfile = session.user;

    return newState;
  }),
  on(AuthActions.setCSRFToken, (state, { csrfToken }) => ({
    ...state,
    csrfToken,
  })),
  on(AuthActions.setUserProfile, (state, { userProfile }) => {
    return {
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        signIn: false,
      },
      userProfile,
    };
  }),
  on(AuthActions.signOut, () => ({
    ...initialState,
  }))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
export const authFeatureKey = 'auth';
