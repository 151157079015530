export const vehicleListUrl = 'vehicle/vehicle_list/';
export const vehicleDataUrl = 'vehicle/vehicle_state/';
export const vehicleAlertsUrl = 'vehicle/vehicle_alerts';
export const vehicleTripsUrl = 'vehicle/trips/';
export const vehicleFleetsUrl = 'vehicle/fleets/';
export const chargingScheduleUrl = 'vehicle/charging_schedule/';
// Geofence Management
export const vehicleGeofenceUrl = 'vehicle/geofence/';
// Command Centre
export const vehicleLogsUrl = 'vehicle/logs/';
export const vehicleRawDataUrl = 'vehicle/raw_latest/';

export const vehicleWakeUpUrl = 'vehicle/wake_up/';
export const vehicleLockUrl = 'vehicle/doors/';
export const vehicleTrunkUrl = 'vehicle/trunk/';
export const vehicleLightsUrl = 'vehicle/flash_lights/';
export const vehicleHornUrl = 'vehicle/honk_horn/';

export const vehicleDefrostUrl = 'vehicle/defrost/';
export const vehicleTemperatureUrl = 'vehicle/set_temperature/';

export const vehicleChargingControlUrl = 'vehicle/charging_control/';
export const vehicleChargingLimitUrl = 'vehicle/charging_limit/';

export const vehicleSentryModeUrl = 'vehicle/sentry_mode/';
export const vehicleValetModeUrl = 'vehicle/valet_mode/';
export const vehicleSpeedLimitModeUrl = 'vehicle/speed_limit_mode/';
export const vehicleSetSpeedLimitUrl = 'vehicle/set_speed_limit';

export const vehicleScheduleSoftwareUpdateUrl = 'vehicle/schedule_software_update/';
export const vehicleCancelSoftwareUpdateUrl = 'vehicle/cancel_software_update/';
