import { Action, createReducer, on } from '@ngrx/store';
import { VehiclesListDTO } from '../real-time-monitoring.models';
import * as RealTimeMonitoringActions from './real-time-monitoring.actions';

export type RealTimeMonitoringState = {
  loadingStatus: {
    vehicleList: boolean;
  };
  vehicleList: VehiclesListDTO | undefined;
};

const initialState: RealTimeMonitoringState = {
  loadingStatus: {
    vehicleList: false,
  },
  vehicleList: undefined,
};

const reducer = createReducer<RealTimeMonitoringState>(
  initialState,
  // -------- Vehicle List
  on(RealTimeMonitoringActions.getVehicleList, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleList: true,
    },
  })),
  on(RealTimeMonitoringActions.setVehicleList, (state, { vehicleList }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleList: false,
    },
    vehicleList,
  }))
);

export function realTimeMonitoringReducer(
  state: RealTimeMonitoringState | undefined,
  action: Action
): any {
  return reducer(state, action);
}
export const realTimeMonitoringFeatureKey = 'realTimeMonitoring';
