import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppState } from '../../store/app.reducers';
import { AuthService } from '../utility/auth.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  getSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getSession),
      switchMap(() =>
        this.authService.getSession().pipe(
          map(session => AuthActions.setSession({ session })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 403) this.store.dispatch(AuthActions.getCSRFToken());

            return of(AuthActions.setSession({ session: { isAuthenticated: false } }));
          })
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      switchMap(() =>
        this.authService.signOut().pipe(
          map(() => AuthActions.reset()),
          catchError(err => {
            console.log('Unable to Sign Out');
            console.error(err);

            return of();
          })
        )
      )
    )
  );

  getCSRFToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCSRFToken),
      switchMap(() =>
        this.authService.getCSRFToken().pipe(
          map(response =>
            AuthActions.setCSRFToken({ csrfToken: response?.headers.get('X-CSRFToken') as string })
          ),
          catchError(() => of(AuthActions.setCSRFToken({ csrfToken: null })))
        )
      )
    )
  );

  // getWhoAmI$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.getWhoAmI),
  //     switchMap(() =>
  //       this.authService.whoAmI().pipe(
  //         map(userProfile => {
  //           this.store.dispatch(AuthActions.setSession({ isAuthenticated: true }));
  //           return AuthActions.setUserProfile({ userProfile })
  //         }),
  //         catchError(error => {
  //           console.log('Could not get identity', error);
  //           return of(AuthActions.setUserProfile({ userProfile: null }));
  //         })
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>
  ) {}
}
