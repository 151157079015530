export const CUSTOM_ICON_REGISTRY = [
  { icon: 'parking', url: '/assets/icons/svg/parking.svg' },
  { icon: 'pending', url: '/assets/icons/svg/pending.svg' },
  { icon: 'temperature', url: '/assets/icons/svg/temperature.svg' },
  { icon: 'moving', url: '/assets/icons/svg/moving.svg' },
  { icon: 'moving_2', url: '/assets/icons/svg/moving_2.svg' },
  { icon: '403', url: '/assets/icons/svg/403.svg' },
  { icon: '404', url: '/assets/icons/svg/404.svg' },
  { icon: '500', url: '/assets/icons/svg/500.svg' },
  { icon: 'ac', url: '/assets/icons/svg/ac.svg' },
  { icon: 'trash_can', url: '/assets/icons/svg/trash-can.svg' },
  { icon: 'add', url: '/assets/icons/svg/add.svg' },
  { icon: 'wipers', url: '/assets/icons/svg/wipers.svg' },
  { icon: 'alerts', url: '/assets/icons/svg/alerts.svg' },
  { icon: 'battery_level', url: '/assets/icons/svg/battery-level.svg' },
  { icon: 'charging', url: '/assets/icons/svg/charging.svg' },
  { icon: 'charging_2', url: '/assets/icons/svg/charging_2.svg' },
  { icon: 'trunk', url: '/assets/icons/svg/trunk.svg' },
  { icon: 'defrost', url: '/assets/icons/svg/defrost.svg' },
  { icon: 'flash', url: '/assets/icons/svg/flash.svg' },
  { icon: 'horn', url: '/assets/icons/svg/horn.svg' },
  { icon: 'check', url: '/assets/icons/svg/import_check.svg' },
  { icon: 'wifi', url: '/assets/icons/svg/wifi.svg' },
  { icon: 'lock', url: '/assets/icons/svg/lock.svg' },
  { icon: 'unlock', url: '/assets/icons/svg/unlock.svg' },
  { icon: 'map_pointer', url: '/assets/icons/svg/map_pointer.svg' },
  { icon: 'upload', url: '/assets/icons/svg/upload.svg' },
  { icon: 'moon', url: '/assets/icons/svg/moon.svg' },
  { icon: 'online', url: '/assets/icons/svg/online.svg' },
  { icon: 'search', url: '/assets/icons/svg/search.svg' },
  { icon: 'calendar', url: '/assets/icons/svg/calendar.svg' },
  { icon: 'notification', url: '/assets/icons/svg/notification.svg' },
];
