import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { MonitoringAppState } from '../monitoring.reducer';
import { MonitoringDataService } from './monitoring-data.service';
import * as MonitoringActions from './monitoring.actions';
import { selectVehicleList } from './monitoring.selectors';

@Injectable()
export class MonitoringGeneralEffects {
  getVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleList),
      switchMap(() =>
        this.dataService.getVehicleList().pipe(
          map(vehicleList => MonitoringActions.setVehicleList({ vehicleList })),
          catchError(() => of(MonitoringActions.setVehicleList({ vehicleList: null })))
        )
      )
    )
  );

  createVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.createVehicleList),
      switchMap(action =>
        this.dataService.createVehicleList(action.vehicleList).pipe(
          map(vehicleList => MonitoringActions.setVehicleList({ vehicleList })),
          catchError(() =>
            of(MonitoringActions.setVehicleList({ vehicleList: null, noUpdate: true }))
          )
        )
      )
    )
  );

  deleteVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.deleteVehicleList),
      switchMap(action =>
        this.dataService.deleteVehicleList(action.vehicleIds).pipe(
          map(vehicleList => MonitoringActions.setVehicleList({ vehicleList })),
          catchError(() =>
            of(MonitoringActions.setVehicleList({ vehicleList: null, noUpdate: true }))
          )
        )
      )
    )
  );

  getFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getFleetList),
      switchMap(() =>
        this.dataService.getFleetList().pipe(
          map(fleetList => MonitoringActions.setFleetList({ fleetList })),
          catchError(() => of(MonitoringActions.setFleetList({ fleetList: null })))
        )
      )
    )
  );

  createFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.createFleetList),
      switchMap(action =>
        this.dataService.createFleetList(action.fleetList).pipe(
          map(fleetList => MonitoringActions.setFleetList({ fleetList })),
          catchError(() => of(MonitoringActions.setFleetList({ fleetList: null, noUpdate: true })))
        )
      )
    )
  );

  deleteFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.deleteFleetList),
      switchMap(action =>
        this.dataService.deleteFleetList(action.fleetIds).pipe(
          map(fleetList => MonitoringActions.setFleetList({ fleetList })),
          catchError(() => of(MonitoringActions.setFleetList({ fleetList: null, noUpdate: true })))
        )
      )
    )
  );

  getVehicleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleData),
      withLatestFrom(this.store.select(selectVehicleList)),
      switchMap(([action, vehicleList]) =>
        this.dataService
          .getVehicleData(
            action.vehicles
              ? action.vehicles
              : vehicleList
              ? vehicleList.map(v => v.id as number)
              : []
          )
          .pipe(
            map(vehicleData => MonitoringActions.setVehicleData({ vehicleData })),
            catchError(() => of(MonitoringActions.setVehicleData({ vehicleData: null })))
          )
      )
    )
  );

  getVehicleAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleAlerts),
      switchMap(action =>
        this.dataService.getVehicleAlerts(action.time_0, action.time_1, action.vehicle).pipe(
          map(alerts => MonitoringActions.setVehicleAlerts({ alerts })),
          catchError(() => of(MonitoringActions.setVehicleAlerts({ alerts: null })))
        )
      )
    )
  );

  getVehicleRawData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleRawData),
      switchMap(action =>
        this.dataService.getVehicleRawData(action.vehicles).pipe(
          map(rawData => MonitoringActions.setVehicleRawData({ rawData })),
          catchError(() => of(MonitoringActions.setVehicleRawData({ rawData: null })))
        )
      )
    )
  );

  getVehicleCommandLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleLogs),
      switchMap(action =>
        this.dataService.getVehicleLogs(action.vehicles).pipe(
          map(vehicleLogs => MonitoringActions.setVehicleLogs({ vehicleLogs })),
          catchError(() => of(MonitoringActions.setVehicleLogs({ vehicleLogs: null })))
        )
      )
    )
  );

  // --------------- Charging Schedules

  createChargingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.createVehicleChargingSchedule),
      switchMap(action =>
        this.dataService.createChargingSchedule(action.schedule, action.vehicles).pipe(
          map(schedules => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          catchError((err: Error) =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  getChargingSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleChargingSchedules),
      switchMap(action =>
        this.dataService.getChargingSchedules(action.vehicles).pipe(
          map(schedules => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          catchError((err: Error) =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  deleteChargingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.deleteVehicleChargingSchedule),
      switchMap(action =>
        this.dataService.deleteChargingSchedule(action.schedule_id, action.vehicles).pipe(
          map(schedules => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          catchError(err =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: MonitoringDataService,
    private store: Store<MonitoringAppState>
  ) {}
}
