import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DistanceUnitPipe } from './distance-unit.pipe';
import { SpeedUnitPipe } from './speed-unit.pipe';
import { TimeFormatPipe } from './time-format.pipe';

@NgModule({
  declarations: [DistanceUnitPipe, SpeedUnitPipe, TimeFormatPipe],
  imports: [CommonModule],
  exports: [DistanceUnitPipe, SpeedUnitPipe, TimeFormatPipe],
  providers: [DecimalPipe],
})
export class UnitConversionModule {}
