import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { VehicleAnalyticsDataService } from './vehicle-analytics-data.service';
import { selectVehicleAnalyticsFilters } from './vehicle-analytics.selectors';
import * as VehicleAnalyticsActions from './vehicle-analytics.actions';

@Injectable()
export class VehicleAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private dataService: VehicleAnalyticsDataService,
    private store: Store
  ) {}
}
