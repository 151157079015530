import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZEVA_PAGES } from '../core/core.static';
import { AnalyticsComponent } from './analytics.component';

const analyticsRoutes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ZEVA_PAGES['analytics-vehicle'].slug,
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/vehicle/vehicle-analytics.module').then(m => m.VehicleAnalyticsModule),
        data: { title: ZEVA_PAGES['analytics-vehicle'].name },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(analyticsRoutes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
