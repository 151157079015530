import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentPlaceholderComponent } from './content-placeholder.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  imports: [CommonModule, LoadingModule],
  declarations: [ContentPlaceholderComponent],
  exports: [ContentPlaceholderComponent],
})
export class ContentPlaceholderModule {}
