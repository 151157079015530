import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService implements OnDestroy {
  set quickAccessItem(item) {
    this._quickAccessItem = item;
    this.quickAccessItem$.next(this._quickAccessItem);
  }
  get quickAccessItem() {
    return this._quickAccessItem;
  }
  private _quickAccessItem?: TemplateRef<unknown>;
  public readonly quickAccessItem$ = new Subject<this['quickAccessItem']>();

  constructor() {}

  ngOnDestroy(): void {
    this.quickAccessItem$.complete();
  }
}
