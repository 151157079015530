import { createAction, props } from '@ngrx/store';
import { VehicleAlertsResponse } from '../../../monitoring/monitoring.models';
import {
  ChargingSchedule,
  VehicleLogEntry,
  Fleet,
  FleetDTO,
  Vehicle,
  VehicleDTO,
  VehicleState,
} from '../../../shared/vehicle/vehicle.models';

// TODO: v Move this to some shared area

export const getVehicleList = createAction('[Monitoring] Get Vehicle List');
export const setVehicleList = createAction(
  '[Monitoring] Set Vehicle List',
  props<{ vehicleList: Vehicle[] | null; noUpdate?: true }>()
);
export const createVehicleList = createAction(
  '[Monitoring] Create Vehicle List',
  props<{ vehicleList: VehicleDTO[] }>()
);
export const deleteVehicleList = createAction(
  '[Monitoring] Delete From Vehicle List',
  props<{ vehicleIds: string[] }>()
);

export const getFleetList = createAction('[Monitoring] Get Vehicle Fleets');
export const setFleetList = createAction(
  '[Monitoring] Set Vehicle Fleets',
  props<{ fleetList: Fleet[] | null; noUpdate?: true }>()
);
export const createFleetList = createAction(
  '[Monitoring] Create Fleet List',
  props<{ fleetList: FleetDTO[] }>()
);
export const deleteFleetList = createAction(
  '[Monitoring] Delete Fleet List',
  props<{ fleetIds: number[] }>()
);

// TODO: ^ Move this to some shared area

export const getVehicleData = createAction(
  '[Monitoring] Get Vehicle Data',
  props<{ vehicles?: number[] }>()
);
export const setVehicleData = createAction(
  '[Monitoring] Set Vehicle Data',
  props<{ vehicleData: VehicleState[] | null }>()
);

export const getVehicleAlerts = createAction(
  '[Monitoring] Get Vehicle Alerts',
  props<{
    time_0?: string;
    time_1?: string;
    vehicle?: number[];
  }>()
);
export const setVehicleAlerts = createAction(
  '[Monitoring] Set Vehicle Alerts',
  props<{ alerts: VehicleAlertsResponse | null }>()
);

// TODO: Reconsider this approach, since it is non-extensible
export const getVehicleRawData = createAction(
  '[Monitoring] Get Vehicle Raw Data',
  props<{ vehicles: number[] }>()
);
export const setVehicleRawData = createAction(
  '[Monitoring] Set Vehicle Raw Data',
  // type of rawData returned is unknown, since it is defined by a vehicle provider
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ rawData: any[] | null }>()
);

export const getVehicleLogs = createAction(
  '[Monitoring] Get Vehicle Logs',
  props<{ vehicles: number[] }>()
);
export const setVehicleLogs = createAction(
  '[Monitoring] Set Vehicle Logs',
  props<{ vehicleLogs: VehicleLogEntry[] | null }>()
);

// -------------- Charging Schedule
export const createVehicleChargingSchedule = createAction(
  '[Monitoring] Create Vehicle Charging Schedule',
  props<{ schedule: ChargingSchedule; vehicles: number[] }>()
);
export const deleteVehicleChargingSchedule = createAction(
  '[Monitoring] Delete Vehicle Charging Schedules',
  props<{ schedule_id: number; vehicles: number[] }>()
);
export const getVehicleChargingSchedules = createAction(
  '[Monitoring] Get Vehicle Charging Schedules',
  props<{ vehicles: number[] }>()
);
export const setVehicleChargingSchedules = createAction(
  '[Monitoring] Set Vehicle Charging Schedules',
  props<{ schedules: ChargingSchedule[] | null; error?: string }>()
);
export const setVehicleChargingScheduleError = createAction(
  '[Monitoring] Set Vehicle Charging Schedule Error',
  props<{ error: string }>()
);
