import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../../http-client-wrapper';
import { VehicleDataDTO, VehiclesListDTO } from '../real-time-monitoring.models';

const authToken = 'na-e9a3706e4f08457690d70a9c2b53c3cbdbc2c729fba3ee4684e8e27c2c04bf69';

@Injectable()
export class RealTimeMonitoringStoreService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  getVehicleList(): Observable<VehiclesListDTO> {
    // return this.httpClientWrapper.get(`vehicle-list/`);
    return this.httpClient.request<VehiclesListDTO>(
      'GET',
      'https://010e6pq9i5.execute-api.ca-central-1.amazonaws.com/tesla-vehicles'
      // { headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Authorization': `Bearer ${authToken}`,
      // }) }
    );
  }

  getVehicleRealTimeData(vehicleId: string): Observable<VehicleDataDTO> {
    // return this.httpClientWrapper.get('vehicle-real-time-data/', { vehicle_id: vehicleId });
    return this.httpClient.request<VehicleDataDTO>(
      'GET',
      'https://fxaeejsq29.execute-api.ca-central-1.amazonaws.com/tesla-vehicle-state',
      {
        // headers: new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Authorization': `Bearer ${authToken}`,
        // }),
        params: { vehicle_id: vehicleId },
      }
    );
  }
}
