import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppState } from '../../store/app.reducers';
import { selectSession, selectUserProfile } from '../store/auth.selectors';

@Injectable({ providedIn: 'root' })
class AuthGuardService {
  constructor(private store: Store<AppState>, private route: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(selectUserProfile),
      this.store.select(selectSession),
    ]).pipe(
      first(([, isAuthenticated]) => isAuthenticated !== undefined),
      map(([userProfile, isAuthenticated]) => {
        const allowed = !!userProfile;

        if (!isAuthenticated) void this.route.navigate(['/auth/login/']);

        return allowed;
      })
    );
  }
}

export const isAuthenticated: CanActivateFn = () => inject(AuthGuardService).canActivate();
