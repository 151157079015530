<mat-toolbar role="heading" class="zeva-header">
  <div class="left-header">
    <div class="sidenav-trigger" (click)="toggleSidenav()">
      <mat-icon>{{ sidenavService.opened ? 'close' : 'menu' }}</mat-icon>
    </div>
  </div>
  <div class="right-header">
    <div class="quick-access" [ngClass]="{ enabled: quickAccessTemplate !== undefined }">
      <ng-container *ngIf="quickAccessTemplate !== undefined">
        <ng-container *ngTemplateOutlet="quickAccessTemplate"></ng-container>
      </ng-container>
    </div>

    <mat-icon class="header-search">search</mat-icon>
    <mat-icon class="header-notifications">notifications</mat-icon>

    <!-- Account Management Menu -->
    <div
      *ngIf="(user$ | async) as user"
      class="header-account"
      [ngbPopover]="accountMenu"
      [placement]="['bottom', 'bottom-right']"
    >
      Welcome, {{ user.name }}
    </div>
    <ng-template #accountMenu>
      <div class="account-menu">
        <button mat-flat-button routerLink="/admin/profile">
          <mat-icon>tune</mat-icon>
          <span>Preferences</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-flat-button (click)="onSignOut()">
          <mat-icon>logout</mat-icon>
          <span>Sign Out</span>
        </button>
      </div>
    </ng-template>
  </div>
</mat-toolbar>
