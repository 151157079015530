import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsPageActivePipe, SidenavComponent } from './sidenav.component';
import { PermissionsModule } from '../../auth/permissions/permissions.module';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [SidenavComponent, IsPageActivePipe],
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule, PermissionsModule],
  exports: [SidenavComponent],
})
export class SidenavModule {}
