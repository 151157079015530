import { createAction, props } from '@ngrx/store';
import {
  ActivityInsightsData,
  AlertEventsData,
  ChargingLevelsData,
  EnergyConsumptionData,
  GenericInsightsRequestPayload,
  MileageRankingData,
  UsageInsightsData,
} from '../../insights.models';

export const clearState = createAction('[Insights] Clear State');

/* Travel Analytics */
export const getUsageInsights = createAction(
  '[Insights] Get Usage Insights',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setUsageInsights = createAction(
  '[Insights] Set Usage Insights',
  props<{ usageInsights: UsageInsightsData | null }>()
);

export const getMileageRanking = createAction(
  '[Insights] Get Mileage Ranking',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setMileageRanking = createAction(
  '[Insights] Set Mileage Ranking',
  props<{ mileageRanking: MileageRankingData | null }>()
);

export const getActivityInsights = createAction(
  '[Insights] Get Activity Insights',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setActivityInsights = createAction(
  '[Insights] Set Activity Insights',
  props<{ activityInsights: ActivityInsightsData | null }>()
);

export const getChargingLevelsInsights = createAction(
  '[Insights] Get Charging Levels Insights',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setChargingLevelsInsights = createAction(
  '[Insights] Set Charging Levels Insights',
  props<{ chargingLevels: ChargingLevelsData | null }>()
);

export const getEnergyConsumptionRanking = createAction(
  '[Insights] Get Energy Consumption Ranking',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setEnergyConsumptionRanking = createAction(
  '[Insights] Set Energy Consumption Ranking',
  props<{ energyConsumption: EnergyConsumptionData | null }>()
);

export const getAlertEventsInsights = createAction(
  '[Insights] Get Alert Events Insights',
  props<{ payload: GenericInsightsRequestPayload }>()
);
export const setAlertEventsInsights = createAction(
  '[Insights] Set Alert Events Insights',
  props<{ alertInsights: AlertEventsData | null }>()
);
