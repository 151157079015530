import { createAction, props } from '@ngrx/store';
import { Provider, ProviderCredential } from '../../admin.models';

export const clearState = createAction('[Admin] Clear State');

export const getSupportedProviders = createAction('[Admin] Get Supported Providers');
export const setSupportedProviders = createAction(
  '[Admin] Set Supported Providers',
  props<{ providers: Provider[] | null }>()
);

export const getProviderCredentials = createAction('[Admin] Get Provider Credentials');
export const setProviderCredentials = createAction(
  '[Admin] Set Provider Credentials',
  props<{ providerCredentials: ProviderCredential[] | null }>()
);
