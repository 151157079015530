import { Action, createReducer, on } from '@ngrx/store';
import { VehicleAnalyticsFilters } from '../vehicle-analytics.models';
import * as VehicleAnalyticsActions from './vehicle-analytics.actions';

export type VehicleAnalyticsState = {
  loadingStatus: {
    // anything
  };
  queryFilters?: VehicleAnalyticsFilters | null;
};

const initialState: VehicleAnalyticsState = {
  loadingStatus: {
    // anything
  },
  queryFilters: undefined,
};

const reducer = createReducer<VehicleAnalyticsState>(
  initialState,
  on(VehicleAnalyticsActions.setVehicleAnalyticsFilters, (state, { filters }) => ({
    ...state,
    queryFilters: filters,
  }))
);

export function vehicleAnalyticsReducer(
  state: VehicleAnalyticsState | undefined,
  action: Action
): VehicleAnalyticsState {
  return reducer(state, action);
}
export const vehicleAnalyticsFeatureKey = 'vehicleAnalytics';
