import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientWrapper } from '../../../http-client-wrapper';
import { Observable } from 'rxjs';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  mileageDataUrl,
  activityDataUrl,
  chargingDataUrl,
  alertsDataUrl,
  usageChargingHeatmapUrl,
  geofenceAlertsAnalyticsUrl,
  chargingAnalyticsUrl,
  usageAnalyticsUrl,
  vehicleRankingDataUrl,
  dataDateRangeUrl,
} from '../../analytics.urls';
import { VehicleState } from '../../../shared/vehicle/vehicle.models';
import {
  UsageAnalyticsData,
  ChargingAnalyticsData,
  VehicleInsightDimension,
  VehicleRankingData,
  AnalyticsDataDateRange,
} from '../../analytics.models';

@Injectable({ providedIn: 'root' })
export class AnalyticsDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  public getVehicleAnalyticsDataDateRange(): Observable<AnalyticsDataDateRange> {
    return this.httpClientWrapper.get(dataDateRangeUrl);
  }

  public getVehicleTravelData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[]
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(mileageDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleActivityData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[]
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(activityDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleChargingData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[]
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(chargingDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleAlertData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[]
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(alertsDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getUsageChargingHeatmapData(
    time_0: Date | undefined,
    time_1: Date | undefined,
    vehicles: number[]
  ): Observable<VehicleState[]> {
    return this.httpClientWrapper.post(usageChargingHeatmapUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getUsageAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[]
  ): Observable<UsageAnalyticsData> {
    return this.httpClientWrapper.post(usageAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getChargingAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[]
  ): Observable<ChargingAnalyticsData> {
    return this.httpClientWrapper.post(chargingAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getGeofenceAlertsAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[]
  ): Observable<any> {
    return this.httpClientWrapper.post(geofenceAlertsAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getVehicleRanking(
    dimension: VehicleInsightDimension,
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[]
  ): Observable<VehicleRankingData> {
    return this.httpClientWrapper.post(vehicleRankingDataUrl, {
      dimension,
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }
}
