import { NgModule } from '@angular/core';
import { IdleWarningDialogComponent } from './idle-warning-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [IdleWarningDialogComponent],
  imports: [MatButtonModule, MatIconModule, CommonModule],
})
export class IdleWarningDialogModule {}
