import { createAction, props } from '@ngrx/store';
import { VehicleAlertsResponse } from '../../../../monitoring/monitoring.models';
import { VehicleState } from '../../../../shared/vehicle/vehicle.models';

export const getVehicleTrips = createAction(
  '[Monitoring - Historical Tracking] Get Vehicle Trips',
  props<{ time_0: Date; time_1: Date; vehicle: number }>()
);
export const setVehicleTrips = createAction(
  '[Monitoring - Historical Tracking] Set Vehicle Trips',
  props<{ tripsStates: VehicleState[] | null }>()
);

export const getVehicleHistoricalAlerts = createAction(
  '[Monitoring - Historical Tracking] Get Vehicle Historical Trips',
  props<{ time_0: string; time_1: string; vehicle: [number] }>()
);
export const setVehicleHistoricalAlerts = createAction(
  '[Monitoring - Historical Tracking] Set Vehicle Historical Trips',
  props<{ alerts: VehicleAlertsResponse | null }>()
);
