import { createFeatureSelector } from '@ngrx/store';
import {
  geofenceManagementFeatureKey,
  GeofenceManagementState,
} from '../pages/geofence-management/store/geofence-management.reducers';
import {
  historicalTrackingFeatureKey,
  HistoricalTrackingState,
} from '../pages/historical-tracking/store/historical-tracking.reducer';
import {
  realTimeMonitoringFeatureKey,
  RealTimeMonitoringState,
} from '../pages/real-time-monitoring/store/real-time-monitoring.reducers';
import { monitoringGeneralFeatureKey, MonitoringGeneralState } from './general/monitoring.reducer';

export const selectFeatureGeneralMonitoring = createFeatureSelector<MonitoringGeneralState>(
  monitoringGeneralFeatureKey
);

export const selectFeatureRealTimeMonitoring = createFeatureSelector<RealTimeMonitoringState>(
  realTimeMonitoringFeatureKey
);

export const selectFeatureHistoricalTracking = createFeatureSelector<HistoricalTrackingState>(
  historicalTrackingFeatureKey
);

export const selectFeatureGeofenceManagement = createFeatureSelector<GeofenceManagementState>(
  geofenceManagementFeatureKey
);
