import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent, WidgetSourceDirective } from './widget.component';
import { CardGridModule } from '../../core/card-grid/card-grid.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WidgetHelpersModule } from './helpers/widget-helpers.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContentPlaceholderModule } from '../content-placeholder/content-placeholder.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeFormatPipe } from '../helpers/pipes/unit-conversion/time-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    CardGridModule,
    WidgetHelpersModule,
    MatButtonModule,
    MatIconModule,
    NgbPopoverModule,
    DragDropModule,
    ContentPlaceholderModule,
  ],
  declarations: [WidgetComponent, WidgetSourceDirective],
  exports: [WidgetComponent, WidgetSourceDirective],
  providers: [TimeFormatPipe],
})
export class WidgetModule {}
