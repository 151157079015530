import { createAction, props } from '@ngrx/store';
import { VehiclesListDTO } from '../../real-time-monitoring/real-time-monitoring.models';
import { GeofenceDTO } from '../geofence-management.models';

export const getGeofenceList = createAction('[Geofence Management] Get Geofence List');
export const setGeofenceList = createAction(
  '[Geofence Management] Set Geofence List',
  props<{ geofenceList: GeofenceDTO[] | undefined }>()
);

export const getVehicles = createAction('[Geofence Management] Get Vehicles');
export const setVehicles = createAction(
  '[Geofence Management] Set Vehicles',
  props<{ vehicles: VehiclesListDTO | undefined }>()
);

export const createGeofence = createAction('[Geofence Management] Create Geofence', props<any>());

export const editGeofence = createAction('[Geofence Management] Edit Geofence', props<any>());

export const deleteGeofence = createAction(
  '[Geofence Management] Delete Geofence',
  props<{ geofenceId: number[] }>()
);
