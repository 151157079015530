import { Component, ChangeDetectionStrategy } from '@angular/core';
import { getRouterOutletState } from '../../shared/helpers/utility';
import { routerTransition } from '../core.static';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(public readonly sidenavService: SidenavService) {}

  getState = getRouterOutletState;
}
