import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from '../app-routing.module';

import { HomeComponent } from './home/home.component';
import { IdleWatchComponent } from './idle-watch/idle-watch.component';
import { HeaderModule } from './header/header.module';
import { SidenavModule } from './sidenav/sidenav.module';

@NgModule({
  declarations: [HomeComponent, IdleWatchComponent],
  imports: [CommonModule, HeaderModule, SidenavModule, MatSidenavModule, AppRoutingModule],
  exports: [IdleWatchComponent],
})
export class CoreModule {}
