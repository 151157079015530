<div class="zeva-fixed-header-left">
  <div class="logo-container">
    <img src="/assets/zeva-logo-no-icon.png" />
  </div>
</div>

<mat-sidenav-container class="zeva-main-container">

  <mat-sidenav #sidenav mode="side" [opened]="sidenavService.opened" class="sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="main-content">
    <app-header></app-header>

    <router-outlet class="mat-app-background page-container"></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
