import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClientWrapper } from '../../http-client-wrapper';
import { permissionCheckUrl } from '../utility/auth.urls';
import { CanActivateFn } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
class PermissionsGuardService {
  constructor(private httpClient: HttpClientWrapper, private _snackBar: MatSnackBar) {}

  canActivate(permissions: string[]): Observable<boolean> {
    return this.httpClient.get<{ allowed: boolean }>(permissionCheckUrl, { p: permissions }).pipe(
      map(response => {
        if (!response.allowed) {
          this._snackBar.open(
            'Insufficient Permissions. Missing: ' + permissions.join(', '),
            'Sure',
            {
              duration: 5000,
            }
          );
        }

        return response.allowed;
      })
    );
  }
}

export function hasPermissions(...permissions: string[]): CanActivateFn {
  return () => {
    return inject(PermissionsGuardService).canActivate(permissions);
  };
}
