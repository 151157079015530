import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MonitoringComponent } from './monitoring.component';
import {
  ZEVA_PAGES,
  ZEVA_PAGE_EXECUTE_PERMISSION,
  ZEVA_PAGE_VIEW_PERMISSION,
} from '../core/core.static';
import { hasPermissions } from '../auth/permissions/permissions.guard';

const monitoringRoutes: Routes = [
  {
    path: '',
    component: MonitoringComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ZEVA_PAGES['monitoring-real-time-monitoring'].slug,
      },
      {
        path: ZEVA_PAGES['monitoring-real-time-monitoring'].slug,
        loadChildren: () =>
          import('./pages/real-time-monitoring/real-time-monitoring.module').then(
            m => m.RealTimeMonitoringModule
          ),
        canActivate: [hasPermissions(ZEVA_PAGE_VIEW_PERMISSION['monitoring-real-time-monitoring'])],
        data: { title: ZEVA_PAGES['monitoring-real-time-monitoring'].name },
      },
      {
        path: ZEVA_PAGES['monitoring-geofence-management'].slug,
        loadChildren: () =>
          import('./pages/geofence-management/geofence-management.module').then(
            m => m.GeofenceManagementModule
          ),
        canActivate: [hasPermissions(ZEVA_PAGE_VIEW_PERMISSION['monitoring-geofence-management'])],
        data: { title: ZEVA_PAGES['monitoring-geofence-management'].name },
      },
      {
        path: ZEVA_PAGES['monitoring-historical-tracking'].slug,
        loadChildren: () =>
          import('./pages/historical-tracking/historical-tracking.module').then(
            m => m.HistoricalTrackingModule
          ),
        canActivate: [hasPermissions(ZEVA_PAGE_VIEW_PERMISSION['monitoring-historical-tracking'])],
        data: { title: ZEVA_PAGES['monitoring-historical-tracking'].name },
      },
      {
        path: ZEVA_PAGES['monitoring-command-centre'].slug,
        loadChildren: () =>
          import('./pages/command-centre/command-centre.module').then(m => m.CommandCentreModule),
        canActivate: [hasPermissions(ZEVA_PAGE_EXECUTE_PERMISSION['monitoring-command-centre'])],
        data: { title: ZEVA_PAGES['monitoring-command-centre'].name },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(monitoringRoutes)],
  exports: [RouterModule],
})
export class MonitoringRoutingModule {}
