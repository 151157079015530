import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { routerTransition } from '../core/core.static';
import { HttpClientWrapper } from '../http-client-wrapper';
import { getRouterOutletState } from '../shared/helpers/utility';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent implements OnInit {
  constructor(private httpClientWrapper: HttpClientWrapper) {}

  ngOnInit(): void {}

  getState = getRouterOutletState;
}
