import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { vehicleGeofenceUrl } from '../../../monitoring.urls';
import { HttpClientWrapper } from '../../../../http-client-wrapper';
import { VehiclesListDTO } from '../../real-time-monitoring/real-time-monitoring.models';
import { GeofenceDTO } from '../geofence-management.models';

@Injectable()
export class GeofenceManagementStoreService {
  constructor(private httpClientWrapper: HttpClientWrapper) {}

  /**
   * Performs an http request to retrieve the geofence list
   * @returns {Observable<GeofenceDTO[]>} - The geofence list
   * @author Juan Corral
   */
  public getGeofenceList(): Observable<GeofenceDTO[]> {
    return this.httpClientWrapper.get(vehicleGeofenceUrl);
  }

  /**
   * Performs an http request to retrieve the vehicles
   * @returns {Observable<VehiclesListDTO>} - The vehicle list
   * @author Juan Corral
   */
  public getVehicles(): Observable<VehiclesListDTO> {
    return this.httpClientWrapper.get('geofence-management/vehicles');
  }

  /**
   * Performs an http request to create a geofence
   * @param {any} geofence - The geofence data
   * @returns {Observable<GeofenceDTO[]>} - The geofence list
   * @author Juan Corral
   */
  public createGeofence(geofence: any): Observable<GeofenceDTO> {
    return this.httpClientWrapper.post(vehicleGeofenceUrl, {
      geofence,
    });
  }

  /**
   * Performs an http request to edit a geofence
   * @param {any} geofence - The geofence data
   * @returns {Observable<GeofenceDTO[]>} - The geofence list
   * @author Juan Corral
   */
  public editGeofence(geofence: any): Observable<GeofenceDTO> {
    return this.httpClientWrapper.patch('geofence-management/edit-geofence', {
      geofence,
    });
  }

  /**
   * Performs an http request to delete a geofence
   * @param {number} geofenceId - The id of the geofence to delete
   * @returns {Observable<GeofenceDTO[]>} - The geofence list
   * @author Juan Corral
   */
  public deleteGeofence(geofenceId: number[]): Observable<number[]> {
    return this.httpClientWrapper.delete((vehicleGeofenceUrl as string) + geofenceId.join(','), {});
  }
}
